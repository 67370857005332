import { useLazyQuery, useMutation } from "@apollo/client";
import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import { DefaultButton, LinkButton } from "components/Buttons";
import Icon from "components/Icon";
import { tableColumnCommonData, sortingTableData, SortTypeEnum, SortOrderEnum } from "components/Table";
import { ConfirmModal } from "components/modal";
import DATE_FORMAT from "constants/dateFormat";
import TABLE from "constants/table";
import { useNotificationContext } from "contexts/NotificationProvider";
import dayjs from "dayjs";
import { getFilenameFromUrl, removeExtensionFromName } from "features/employee/leave";
import { useWindowInfiniteScroll } from "hooks";
import useFormModal from "hooks/useFormModal";
import { PageInfo } from "model/Common";
import {
  documentShortVisibilityTypeLabel,
  Documents,
  FetchDocumentsResponse,
  DocumentRefetch,
  DestroyDocument,
} from "model/Document";
import { forwardRef, useImperativeHandle, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import document from "routes/document";
import { useNotify } from "services/api";
import { DESTROY_DOCUMENT, FETCH_DOCUMENTS } from "services/graphql/document";
import styled from "styled-components";
import { downloadLink } from "utils";

const DocumentsListTable = forwardRef<DocumentRefetch>((_, ref) => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Documents>>({});
  const [data, setDocumentsData] = useState<Documents[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [deleteId, setDeleteId] = useState<string>();
  const { employeeDocumentRefetch, refresh } = useNotificationContext();
  const notify = useNotify();
  const {
    isModalVisible: isDestroyConfirmModalVisible,
    hideModal: hideDestroyConfirmModal,
    showModal: showDestroyConfirmModal,
  } = useFormModal();

  const [refetch, { loading }] = useLazyQuery<FetchDocumentsResponse>(FETCH_DOCUMENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      const documents: Documents[] = response.documents.nodes.map((d: Documents) => ({ ...d })) ?? [];
      setDocumentsData((d) => [...d, ...documents]);
      if (response.documents.pageInfo) setPageInfo(response.documents.pageInfo);
    },
  });

  const fetchData = async () => {
    const result = await refetch({ variables: { first: TABLE.maxRowPerPage, after: undefined } });
    if (result.data) {
      const { pageInfo } = result?.data?.documents;
      if (pageInfo?.hasNextPage) {
        refetch({ variables: { first: TABLE.maxRowPerPage, after: pageInfo.endCursor } });
      }
    }
  };

  useLayoutEffect(() => {
    if (!data.length) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [removeDocument] = useMutation<DestroyDocument>(DESTROY_DOCUMENT, {
    onCompleted: (response) => {
      if (response?.destroyDocument?.success) {
        notify.success({ message: "Document deleted." });
        hideDestroyConfirmModal();
        setPageInfo(undefined);
        setDocumentsData([]);
        fetchData();
        employeeDocumentRefetch();
        refresh();
      }
    },
  });

  useImperativeHandle(ref, () => ({
    documentRefetch() {
      setPageInfo(undefined);
      setDocumentsData([]);
      fetchData();
    },
  }));

  useWindowInfiniteScroll({
    hasNextPage: pageInfo?.hasNextPage,
    endCursor: pageInfo?.endCursor,
    loading,
    callback: () => {
      refetch({ variables: { first: TABLE.maxRowPerPage, after: pageInfo?.endCursor } });
    },
  });

  const columns: ColumnsType<Documents> = [
    {
      ...tableColumnCommonData({
        title: "Document name",
        dataIndex: "attachmentName",
        defaultSortOrder: SortOrderEnum.Descend,
        width: "34%",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { attachmentName }) => (
        <div className="d-flex justify-content-start align-items-center gap-2">
          <Icon name="document" />
          <Typography.Title level={2} className="mb-0 title">
            {removeExtensionFromName(attachmentName)}
          </Typography.Title>
        </div>
      ),
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.String, firstData: a!.attachmentName, secondData: b!.attachmentName }),
    },
    {
      ...tableColumnCommonData({
        title: "Type",
        dataIndex: "visibilityType",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { visibilityType }) => documentShortVisibilityTypeLabel[visibilityType],
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.String,
          firstData: documentShortVisibilityTypeLabel[a.visibilityType],
          secondData: documentShortVisibilityTypeLabel[b.visibilityType],
        }),
    },
    {
      ...tableColumnCommonData({
        title: "Shared with",
        dataIndex: "sharedWithCount",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { id, sharedWithCount }) => (
        <Link to={document.view(id)} tabIndex={-1}>
          <LinkButton className="p-0">{sharedWithCount}</LinkButton>
        </Link>
      ),
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.Number, firstData: a.sharedWithCount, secondData: b.sharedWithCount }),
    },
    {
      ...tableColumnCommonData({
        title: "Action completed",
        dataIndex: "actionCompletedCount",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { actionCompletedCount }) => actionCompletedCount || "N/A",
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.Number,
          firstData: a.actionCompletedCount,
          secondData: b.actionCompletedCount,
        }),
    },
    {
      ...tableColumnCommonData({
        title: "Action pending",
        dataIndex: "actionPendingCount",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { actionPendingCount }) => actionPendingCount || "N/A",
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.Number,
          firstData: a.actionPendingCount,
          secondData: b.actionPendingCount,
        }),
    },
    {
      ...tableColumnCommonData({
        title: "Date added",
        dataIndex: "dateAdded",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { dateAdded }) => (dateAdded ? dayjs(dateAdded).format(DATE_FORMAT.fullDateDefault) : "N/A"),
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.dateAdded, secondData: b.dateAdded }),
    },
    {
      title: "",
      dataIndex: "download",
      render: (_, { attachmentUrl }) => (
        <DefaultButton
          onClick={(event) => {
            event.stopPropagation();
            downloadLink(attachmentUrl, getFilenameFromUrl(attachmentUrl));
          }}
        >
          Download
        </DefaultButton>
      ),
      width: 100,
    },
    {
      title: "",
      dataIndex: "delete",
      render: (_, { id }) => (
        <DefaultButton
          className="me-1"
          onClick={(event) => {
            event.stopPropagation();
            showDestroyConfirmModal();
            setDeleteId(id);
          }}
        >
          Delete
        </DefaultButton>
      ),
      width: 80,
    },
  ];

  return (
    <StyledWrapper>
      <Table
        columns={columns}
        dataSource={data ?? []}
        pagination={false}
        loading={loading}
        sticky
        rowKey="id"
        sortDirections={TABLE.tableSortDirections}
        onChange={(_pagination, _filter, sorter) => setSortedInfo(sorter as SorterResult<Documents>)}
      />
      <ConfirmModal
        onCancel={hideDestroyConfirmModal}
        open={isDestroyConfirmModalVisible}
        onOk={() => removeDocument({ variables: { id: deleteId } })}
        width={310}
        title="Are you sure you want to delete this document?"
        okText="Yes"
        cancelText="No"
      />
    </StyledWrapper>
  );
});

export default DocumentsListTable;

const StyledWrapper = styled.div`
  .ant-table-column-sorter {
    display: none;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
  }

  .linkButton {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .ant-table-tbody tr:hover .ant-btn-link {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;
