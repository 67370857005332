import { List, Modal, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { DefaultButton, LinkButton } from "components/Buttons";
import Icon from "components/Icon";
import { useModal } from "components/modal";
import UserAvatar from "components/UserAvatar/UserAvatar";
import COLORS from "constants/colors";
import {
  ComplianceByIssue,
  complianceIssueLabel,
  ComplianceIssuesFilterEnum,
  ComplianceByEmployee,
} from "model/Insights";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import employee from "routes/employee";
import styled from "styled-components";

type Props = {
  filterBy: ComplianceIssuesFilterEnum;
  loading?: boolean;
  data?: ComplianceByEmployee[] | ComplianceByIssue[];
};
const ComplianceIssuesTable = ({ filterBy, loading, data }: Props) => {
  const navigate = useNavigate();

  const { isModalVisible, hideModal, showModal } = useModal();
  const [selectedEmployeeData, setSelectedEmployeeData] = useState<ComplianceByEmployee | ComplianceByIssue>();

  const columnByEmployee: ColumnsType<ComplianceByEmployee> = [
    {
      title: "Employees",
      dataIndex: "name",
      render: (_, { id, name, profileImageUrl }) => (
        <StyledLinkButton onClick={() => navigate(employee.view(id), { state: { isLeaving: false } })}>
          <UserAvatar section="header" image={profileImageUrl} />
          <StyledName>{name}</StyledName>
        </StyledLinkButton>
      ),
    },
    {
      title: "Issue",
      dataIndex: "issues",
      render: (_, r) => (
        <DefaultButton
          onClick={() => {
            setSelectedEmployeeData(r);
            showModal();
          }}
        >
          <StyledDefaultButtonText className="d-flex">
            <span>
              {r.issues.length} {r.issues.length > 1 ? "issues" : "issue"}
            </span>
            <Icon name="popover" />
          </StyledDefaultButtonText>
        </DefaultButton>
      ),
    },
  ];

  const columnByIssue: ColumnsType<ComplianceByIssue> = [
    {
      title: "Issues",
      dataIndex: "issues",
      render: (_, { issue }) => (
        <div className="d-flex align-items-center">
          <StyledIcon name="popover" />
          <StyledName>{complianceIssueLabel[issue]}</StyledName>
        </div>
      ),
    },
    {
      title: "Employees",
      dataIndex: "employees",
      width: 130,
      render: (_, r) => (
        <DefaultButton
          onClick={() => {
            setSelectedEmployeeData(r);
            showModal();
          }}
        >
          <StyledDefaultButtonText className="d-flex">{r.users.length} employees</StyledDefaultButtonText>
        </DefaultButton>
      ),
    },
  ];

  return (
    <StyledTableWrapper>
      {filterBy === ComplianceIssuesFilterEnum.Employee ? (
        <Table
          rowKey="id"
          columns={columnByEmployee}
          dataSource={data as ComplianceByEmployee[]}
          pagination={false}
          loading={loading}
          bordered={false}
          scroll={{
            scrollToFirstRowOnChange: false,
            y: 235,
          }}
        />
      ) : (
        <Table
          rootClassName="issue-table"
          rowKey="issue"
          loading={loading}
          columns={columnByIssue}
          dataSource={data as ComplianceByIssue[]}
          pagination={false}
          bordered={false}
        />
      )}

      {filterBy === ComplianceIssuesFilterEnum.Employee && (
        <Modal
          open={isModalVisible}
          onCancel={hideModal}
          footer={null}
          width={501}
          centered
          destroyOnClose
          rootClassName="secondary"
          title={
            <div className="d-flex align-items-center gap-2">
              <UserAvatar section="table" />
              <StyledModalTitle level={4}>
                {(selectedEmployeeData as ComplianceByEmployee)?.name} compliance issues
              </StyledModalTitle>
            </div>
          }
        >
          <StyledModalContent>
            <List
              bordered
              dataSource={(selectedEmployeeData as ComplianceByEmployee)?.issues ?? []}
              renderItem={(item, index) => (
                <StyledListItem>
                  <Typography.Text>{complianceIssueLabel[item]}</Typography.Text>
                  <StyledIcon name="popover" />
                </StyledListItem>
              )}
            />
            <DefaultButton
              onClick={() =>
                (selectedEmployeeData as ComplianceByEmployee)?.id &&
                navigate(employee.view((selectedEmployeeData as ComplianceByEmployee).id), {
                  state: { isLeaving: false },
                })
              }
            >
              View employee page
            </DefaultButton>
          </StyledModalContent>
        </Modal>
      )}

      {filterBy === ComplianceIssuesFilterEnum.Issue && (
        <Modal
          open={isModalVisible}
          onCancel={hideModal}
          footer={null}
          width={501}
          centered
          destroyOnClose
          rootClassName="secondary"
          title={<StyledModalTitle level={4}>Employees with potential compliance issues</StyledModalTitle>}
        >
          <StyledModalContent>
            <List
              bordered
              dataSource={(selectedEmployeeData as ComplianceByIssue)?.users ?? []}
              renderItem={(item) => (
                <StyledListItem>
                  <div className="d-flex align-items-center gap-2">
                    <UserAvatar section="header" image={item?.profileImageUrl} />
                    <Typography.Text>{item.name}</Typography.Text>
                  </div>
                  <DefaultButton
                    onClick={() => item?.id && navigate(employee.view(item.id), { state: { isLeaving: false } })}
                  >
                    View employee page
                  </DefaultButton>
                </StyledListItem>
              )}
            />
          </StyledModalContent>
        </Modal>
      )}
    </StyledTableWrapper>
  );
};

export default ComplianceIssuesTable;
const StyledLinkButton = styled(LinkButton)`
  &:hover .ant-typography {
    font-weight: 600;
  }
`;

const StyledName = styled(Typography.Text)`
  color: ${COLORS.inputFieldTextColor};
  font-size: 13px;
  font-weight: 500;
  padding-left: 11px;
`;

const StyledTableWrapper = styled.div`
  & .ant-table .ant-table-thead tr th:first-child,
  & .ant-table .ant-table-tbody tr td:first-child {
    padding-left: 20px;
  }
  & .issue-table .ant-table .ant-table-tbody tr:first-child td {
    padding-top: 11px;
  }
  & .ant-table .ant-table-tbody tr:last-child td {
    padding-bottom: 19px;
  }

  & .issue-table .ant-table .ant-table-thead,
  & .ant-table .ant-table-header {
    border-radius: 0px;
    position: relative;
  }

  & .ant-table .ant-table-thead tr th,
  & .ant-table .ant-table-thead tr td,
  & .ant-table .ant-table-tbody tr td {
    border: none;
    font-size: 13px;
  }
  & .ant-table .ant-table-body {
    margin-top: 11px;
  }

  & .issue-table .ant-table .ant-table-thead::before,
  & .ant-table .ant-table-header::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 20px;
    width: calc(100% - 40px);
    border-top: 1px solid ${COLORS.borderLight};
    z-index: 1;
  }
  & .issue-table .ant-table .ant-table-thead::after,
  & .ant-table .ant-table-header::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 20px;
    width: calc(100% - 40px);
    border-bottom: 1px solid ${COLORS.borderLight};
    z-index: 1;
  }

  & .ant-btn.btn-default {
    height: 30px;
  }
`;

const StyledIcon = styled(Icon)`
  &.anticon {
    width: 15px;
    height: 15px;
  }
`;

const StyledDefaultButtonText = styled.span`
  align-items: center;
  font-weight: 600;
  gap: 4px;
`;

const StyledListItem = styled(List.Item)`
  &.ant-list-item {
    padding: 6px 8px 6px 17px;
    height: 44px;
  }

  & .ant-typography {
    color: ${COLORS.inputFieldTextColor};
  }
`;

const StyledModalTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${COLORS.inputFieldTextColor};
    margin: 0;
    padding-top: 7px;
  }
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
  padding-bottom: 6px;

  & .ant-btn.btn-default {
    width: fit-content;
  }
`;
