export const DATE_FORMAT = {
  fullDateDefault: "DD/MM/YY",
  datePickerAllowDate: ["DD/MM/YY", "DD/MM/YYYY", "D/M/YYYY", "D/M/YY", "DD-MM-YYYY", "DD-MM-YY", "D-M-YYYY", "D-M-YY"],
  fullDatePrimary: "DD-MM-YY",
  fullDateSecondary: "DD MMM YY",
  dayOfMonth: "D",
  dayOfWeek: "ddd",
  monthAndYear: "MMM YYYY",
  monthAndYearSecondary: "MM-YYYY",
  isoFormat: "YYYY-MM-DDTHH:mm:ss",
  fullMonthAndYear: "DD MMMM YYYY",
  cardExpiration: "MM/YY",
  fullDate: "DD/MM/YYYY",
  isoFormatWithoutTime: "YYYY-MM-DD",
  shortMonthWithFullYear: "DD MMM YYYY",
  shortMonth: "MMM",
};
export default DATE_FORMAT;
