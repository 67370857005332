import { Modal, ModalProps } from "antd";
import Form, { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { DeclineButton, SuccessButton } from "components/Buttons";
import { InputText } from "components/Inputs";
import { Container } from "components/core";
import COLORS from "constants/colors";
import { useAuthContext } from "contexts";
import { useNotificationContext } from "contexts/NotificationProvider";
import { useApproveLeaveRequest, useDeclineLeaveRequest } from "features/employee/leave";
import { SelectedIdsData } from "model/Leave";
import { useCallback } from "react";
import styled from "styled-components";

export type ItemProps = {
  label: string;
  value?: string | number | null;
};

export interface Data {
  label: string;
  value?: string | number | null;
}

type Props = {
  ids: SelectedIdsData;
  data: Data[];
  onClose(): void;
};

const Item = ({ label, value }: ItemProps) => (
  <StyleListItem>
    <StyledTitle>{label}</StyledTitle>
    <StyledData>{value || "-"}</StyledData>
  </StyleListItem>
);

const UpdateLeaveModal = ({
  ids,
  data,
  okText,
  cancelText,
  onClose,
  ...props
}: Omit<ModalProps, "footer" | "centered"> & Props) => {
  const [form] = useForm();
  const { user } = useAuthContext();
  const { refresh } = useNotificationContext();
  const onSuccess = useCallback(() => {
    refresh();
    onClose();
    form.resetFields();
  }, [form, onClose, refresh]);

  const { mutate: mutateApprove, loading: loadingApprove } = useApproveLeaveRequest(onSuccess, ids.userId);
  const { mutate: mutateDecline, loading: loadingDecline } = useDeclineLeaveRequest(onSuccess);
  return (
    <Modal {...props} footer={null} centered onCancel={onClose}>
      <StyledContainer>
        {data.map(
          (item) =>
            item.label.toLowerCase() !== "attachments" &&
            item.label.toLowerCase() !== "manager notes" && (
              <Item label={item.label} value={item.value ?? ""} key={item.label} />
            ),
        )}
      </StyledContainer>

      <Form layout="vertical" form={form}>
        <FormItem label="Notes" name="notes">
          <StyledTextarea autoSize={{ minRows: 4, maxRows: 4 }} />
        </FormItem>
        <div className="d-flex gap-3 mt-5">
          <SuccessButton
            className="w-100"
            loading={loadingApprove}
            onClick={() =>
              mutateApprove({ variables: { id: ids.requestId, lineManagerNote: form.getFieldValue("notes") } })
            }
          >
            {okText}
          </SuccessButton>
          <DeclineButton
            className="w-100"
            loading={loadingDecline}
            onClick={() =>
              mutateDecline({ variables: { id: ids.requestId, lineManagerNote: form.getFieldValue("notes") } })
            }
          >
            {cancelText}
          </DeclineButton>
        </div>
      </Form>
    </Modal>
  );
};

export default UpdateLeaveModal;

const StyledContainer = styled(Container)`
  background: ${COLORS.tableBgColor};
  padding: 30px 20px;
  margin-bottom: 19px;
`;

const StyleListItem = styled.li`
  width: 100%;
  padding: 6px 0;
  display: flex;
  gap: 4%;
`;

const StyledTitle = styled.span`
  width: 350px;
  line-height: 26px;
  color: ${COLORS.inputFieldTextColor};
`;

const StyledData = styled(StyledTitle)`
  font-weight: 600;
  color: ${COLORS.primaryColor};
  width: 100%;
`;

const StyledTextarea = styled(InputText.TextArea)`
  height: 100px;
`;
