import { Drawer, Typography } from "antd";
import { DrawerProps } from "antd/lib";
import React, { useState } from "react";
import styled from "styled-components";
import BadgeCount from "components/BadgeCount/BadgeCount";
import { useNotificationContext } from "contexts/NotificationProvider";
import { useAuthContext } from "contexts";
import { isAdmin, User } from "model/User";
import { NotificationDrawerTab } from "model/Notification";
import COLORS from "constants/colors";
import EmployeeNotifications from "./notificationTables/employeeNotifications/EmployeeNotifications";
import MyNotifications from "./notificationTables/myNotifications/MyNotifications";

interface NotificationTabProps {
  employeeNotificationCount: number;
  myNotificationCount: number;
  user?: User;
  onClickTab: React.Dispatch<React.SetStateAction<NotificationDrawerTab>>;
  activeTab: NotificationDrawerTab;
}
const NotificationTabs = ({
  employeeNotificationCount,
  myNotificationCount,
  user,
  onClickTab,
  activeTab,
}: NotificationTabProps) => (
  <StyleNotificationWrapper>
    {isAdmin(user) && (
      <StyledTab
        className={`${activeTab === NotificationDrawerTab.Employees ? "active" : ""}`}
        onClick={() => onClickTab(NotificationDrawerTab.Employees)}
      >
        <span>Employee notifications</span>
        <BadgeCount data-testid="employeeNotificationCount">{employeeNotificationCount ?? 0}</BadgeCount>
      </StyledTab>
    )}
    <StyledTab
      className={`${activeTab === NotificationDrawerTab.My ? "active" : ""}`}
      onClick={() => onClickTab(NotificationDrawerTab.My)}
    >
      <span>My notifications</span>
      <BadgeCount data-testid="myNotificationCount">{myNotificationCount ?? 0}</BadgeCount>
    </StyledTab>
  </StyleNotificationWrapper>
);

const NotificationDrawer = ({ open, onClose }: DrawerProps) => {
  const { employeeNotificationCount, myNotificationCount } = useNotificationContext();
  const { user } = useAuthContext();

  const [activeTab, setOpenTab] = useState<NotificationDrawerTab>(
    isAdmin(user) ? NotificationDrawerTab.Employees : NotificationDrawerTab.My,
  );

  return (
    <StyledDrawer
      headerStyle={{ border: "none" }}
      onClose={(e) => {
        if (onClose) onClose(e);
        if (isAdmin(user)) setOpenTab(NotificationDrawerTab.Employees);
      }}
      width={850}
      open={open}
      title={
        <NotificationTabs
          employeeNotificationCount={employeeNotificationCount}
          myNotificationCount={myNotificationCount}
          user={user}
          onClickTab={setOpenTab}
          activeTab={activeTab}
        />
      }
    >
      {isAdmin(user) && activeTab === NotificationDrawerTab.Employees && <EmployeeNotifications />}
      {activeTab === NotificationDrawerTab.My && <MyNotifications />}
    </StyledDrawer>
  );
};

export default NotificationDrawer;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    background: ${COLORS.layoutBgColor};
    .ant-drawer-header-title {
      position: relative;
      button {
        position: absolute;
        right: 30px;
        margin: 0;
        padding: 0;
      }
    }
  }
  .ant-drawer-body {
    padding: 0px;
  }
`;

const StyleNotificationWrapper = styled.div`
  display: flex;
  align-item: center;
`;

const StyledTab = styled(Typography.Text)`
  padding: 23px 28px;
  display: flex;
  align-item: center;
  justify-content: space-between;
  box-shadow: 0px -2px 12px 0px #ebeefc inset;
  color: ${COLORS.headerText};
  cursor: pointer;
  &.active {
    background: ${COLORS.white};
    color: ${COLORS.primaryColor};
    box-shadow: none;
  }
`;
