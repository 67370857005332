import { ModifyMyDocumentNotification, ModifyMyLeaveNotification, NotificationType } from "model/Notification";
import { useNotificationContext } from "contexts/NotificationProvider";
import { useMemo, useState } from "react";
import { notification, Space, Typography } from "antd";
import Icon from "components/Icon";
import dayjs from "dayjs";
import DATE_FORMAT from "constants/dateFormat";
import styled from "styled-components";
import COLORS from "constants/colors";
import { DefaultButton, PrimaryButton } from "components/Buttons";
import { getFilenameFromUrl, removeExtensionFromName } from "features/employee/leave";
import { LeaveRequestsStatus, leaveTypeLabels } from "model/Leave";
import storage from "utils/storage";
import { downloadLink, expectNumber, getTimeAgo } from "utils";
import { useMutation } from "@apollo/client";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import { ActionCompletedResponse } from "model/Document";
import { COMPLETE_ACTIONS } from "services/graphql/document";
import EmptyNotification from "../EmptyNotification";

const MyNotifications = () => {
  const { employeeDocumentNotification } = PERMISSION;
  const { hasEmployeeDocumentNotification } = useCheckPermission({ employeeDocumentNotification });
  const [documentId, setDocumentId] = useState<string>();

  const { refresh, myDocumentData, myDocumentReviewRefetch, employeeDocumentRefetch, myNotificationLeaveData } =
    useNotificationContext();

  const [api, contextHolder] = notification.useNotification({
    getContainer: () => document.getElementById("mount-notification-remove") as any,
  });
  const markAsReadHandler = (type: NotificationType, id?: string) => {
    if (id) {
      storage.addReadNotification(expectNumber(id), type);
    }
    refresh();
    api.success({
      message: type === NotificationType.MyDocument ? "Action completed" : "Notification Removed",
      duration: 1,
      closeIcon: false,
    });
  };

  const [actionComplete, { loading }] = useMutation<ActionCompletedResponse>(COMPLETE_ACTIONS, {
    onCompleted: (response) => {
      if (response.createUserActionConnection) {
        if (!response.createUserActionConnection.errors) {
          markAsReadHandler(NotificationType.MyDocument, documentId);
          myDocumentReviewRefetch();
          if (hasEmployeeDocumentNotification) {
            employeeDocumentRefetch();
          }
        }
      }
    },
  });

  const modifyData = useMemo(() => {
    const modifyLeaveData = (myNotificationLeaveData ?? []).map((item) => ({
      ...item,
      type: NotificationType.Holiday,
      notificationTimeStamp: item.status === LeaveRequestsStatus.Approved ? item.approvedAt : item.declinedAt,
    })) as ModifyMyLeaveNotification[];

    const modifyDocumentData = (myDocumentData ?? []).map((item) => ({
      ...item,
      type: NotificationType.Document,
      notificationTimeStamp: item.createdAt,
    })) as ModifyMyDocumentNotification[];
    const sortData = [
      ...[...modifyDocumentData, ...modifyLeaveData].sort((a, b) =>
        dayjs(b.notificationTimeStamp).isBefore(dayjs(a.notificationTimeStamp)) ? -1 : 1,
      ),
    ];
    return sortData;
  }, [myDocumentData, myNotificationLeaveData]);

  if (modifyData.length === 0) {
    return <EmptyNotification />;
  }

  return (
    <div>
      {contextHolder}
      <StyledRemoveNotificationWrapper id="mount-notification-remove" />
      {modifyData.map((item, index) => {
        if (item.type === NotificationType.Holiday) {
          const leaveData = item as ModifyMyLeaveNotification;
          return (
            <StyledDocumentAndLeaveWrapper direction="vertical" size={[0, 20]} key={`${leaveData.id}${index + 1}`}>
              <div className="d-flex justify-content-between gap-3">
                <StyledDocumentAndLeaveTitle>
                  {`${leaveTypeLabels[leaveData.reason]} your requested from ${dayjs(leaveData.fromDate).format(
                    DATE_FORMAT.fullDate,
                  )} - ${dayjs(leaveData.toDate).format(DATE_FORMAT.fullDate)} has been approved`}
                </StyledDocumentAndLeaveTitle>
                {item?.notificationTimeStamp && (
                  <StyledTimeText>{getTimeAgo(item.notificationTimeStamp)}</StyledTimeText>
                )}
              </div>
              <DefaultButton
                onClick={() => markAsReadHandler(NotificationType.Holiday, `${leaveData.user?.id}${leaveData.id}`)}
              >
                Mark as read
              </DefaultButton>
            </StyledDocumentAndLeaveWrapper>
          );
        }
        const documentData = item as ModifyMyDocumentNotification;
        return (
          <StyledDocumentAndLeaveWrapper direction="vertical" size={[0, 20]} key={`${documentData.id}${index + 1}`}>
            <div className="d-flex justify-content-between gap-3">
              <div>
                <StyledDocumentAndLeaveTitle>
                  “{removeExtensionFromName(documentData.attachmentName)}” document has been shared with you for review
                </StyledDocumentAndLeaveTitle>
                {documentData.action && (
                  <StyledActionDetailWrapper direction="vertical" size={[0, 11]}>
                    <Space size={[16, 0]}>
                      <Icon name="popover" />
                      <StyledActionDetail level={3} className="m-0">
                        Action required: {documentData.action?.description}
                      </StyledActionDetail>
                    </Space>
                    <StyledDeadlineDate>
                      {` Deadline date: ${
                        documentData.action?.deadlineAt
                          ? dayjs(documentData.action?.deadlineAt).format(DATE_FORMAT.fullDate)
                          : "/"
                      }`}
                    </StyledDeadlineDate>
                  </StyledActionDetailWrapper>
                )}
              </div>
              {item?.notificationTimeStamp && <StyledTimeText>{getTimeAgo(item.notificationTimeStamp)}</StyledTimeText>}
            </div>
            <Space size={[10, 0]}>
              <PrimaryButton
                onClick={() => {
                  if (documentData.attachmentUrl) {
                    downloadLink(
                      documentData.attachmentUrl as string,
                      getFilenameFromUrl(documentData.attachmentUrl as string),
                    );
                  }
                }}
              >
                Download document
              </PrimaryButton>
              {documentData.action ? (
                <DefaultButton
                  loading={loading}
                  onClick={() => {
                    actionComplete({ variables: { actionId: documentData?.action?.id } });
                    setDocumentId(documentData?.id);
                  }}
                >
                  Action completed
                </DefaultButton>
              ) : (
                <DefaultButton onClick={() => markAsReadHandler(NotificationType.MyDocument, `${documentData.id}`)}>
                  Mark as read
                </DefaultButton>
              )}
            </Space>
          </StyledDocumentAndLeaveWrapper>
        );
      })}
    </div>
  );
};

export default MyNotifications;

const StyledDocumentAndLeaveTitle = styled(Typography.Text)`
  color: ${COLORS.inputFieldTextColor};
`;

const StyledActionDetail = styled(Typography.Title)`
  font-size: 14px;
`;

const StyledDeadlineDate = styled(Typography.Text)`
  padding-left: 34px;
  font-size: 13px;
  color: ${COLORS.primaryColor};
`;

const StyledActionDetailWrapper = styled(Space)`
  padding: 17px;
  margin-top: 17px;
  border: 1px solid ${COLORS.warningColor};
  background: ${COLORS.warningLightBgColor};
  width: 100%;
  border-radius: 4px;

  svg circle {
  fill:${COLORS.warningColor};
`;

const StyledDocumentAndLeaveWrapper = styled(Space)`
  padding: 24px 20px 24px 27px;
  border-bottom: 1px solid ${COLORS.borderLight};
  width: 100%;
`;

const StyledRemoveNotificationWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;

  & .ant-notification {
    margin: 0;
    margin-inline-end: 0px;
    display: contents;

    & .ant-notification-notice {
      margin: 0;
      width: 100%;
      box-shadow: none;
      background-color: ${COLORS.notificationSuccessBgColor};
      padding: 12px 24px;

      & .ant-notification-notice-message {
        margin-bottom: 0px;
        font-size: 14px;
      }
    }
  }
`;

const StyledTimeText = styled(Typography.Text)`
  &.ant-typography {
    color: ${COLORS.noColor};
    font-size: 13px;
    text-align: end;
    white-space: nowrap;
  }
`;
