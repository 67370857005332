import { ApolloClient, ApolloProvider, InMemoryCache, useMutation } from "@apollo/client";
import { createConsumer } from "@rails/actioncable";
import { Badge, Dropdown, MenuProps, Popover, Space, Typography } from "antd";
import { Header as AntHeader } from "antd/lib/layout/layout";
import { BaseButton, LinkButton } from "components/Buttons";
import Icon from "components/Icon";
import UserAvatar from "components/UserAvatar/UserAvatar";
import PERMISSION from "config/permission";
import COLORS from "constants/colors";
import { useAuthContext } from "contexts";
import { wsUri } from "contexts/ApolloProvider";
import { useNotificationContext } from "contexts/NotificationProvider";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";
import useCheckPermission from "hooks/useCheckPermission";
import { MouseEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import employee from "routes/employee";
import { SIGN_OUT_DOCUMENT } from "services/graphql/user";
import styled from "styled-components";
import storage from "utils/storage";
import NotificationDrawer from "features/notification/NotificationDrawer";
import { useQueryParams } from "hooks";
import useNotifications from "./useNotifications";

const cable = createConsumer(`${wsUri}?x_auth_token=${storage.getAuthToken()}`);
const wsLink = new ActionCableLink({ cable });

export const wsClient = new ApolloClient({
  link: wsLink,
  cache: new InMemoryCache(),
});

type NotificationBadgeProps = { onOpenDrawer: () => void };

const NotificationBadge = ({ onOpenDrawer }: NotificationBadgeProps) => {
  const { notificationsCount, refresh } = useNotificationContext();
  useNotifications(refresh);

  return (
    <StyledBadgeButton onClick={onOpenDrawer} className="badge-btn" data-testid="notificationDrawer">
      <StyledBadge count={notificationsCount}>
        <Icon name="bell" />
      </StyledBadge>
    </StyledBadgeButton>
  );
};

export const Header = () => {
  const navigate = useNavigate();
  const { logout, user } = useAuthContext();
  const { companies } = PERMISSION;
  const { hasCompanies } = useCheckPermission({ companies });
  const { openNotifications, setOpenNotifications } = useNotificationContext();
  const query = useQueryParams();
  const location = useLocation();

  const [logOut, { loading }] = useMutation(SIGN_OUT_DOCUMENT, {
    onCompleted: () => {
      logout();
    },
  });

  const notificationDrawerCloseHandler = () => {
    if (query.get("notification")) {
      const { pathname } = location;
      navigate(pathname, { replace: true });
    }
    setOpenNotifications(false);
  };

  if (!user) return <></>;
  const items: MenuProps["items"] = [
    ...(!hasCompanies && user?.subscriptionActive
      ? [
          {
            key: "myProfile",
            label: (
              <StyledSpace>
                My Profile
                {user.needUpdateProfile && (
                  <Popover title={<StyledPopoverTitle>Profile needs updating</StyledPopoverTitle>} zIndex={9999}>
                    <Icon name="popover" onClick={(e: MouseEvent) => e.stopPropagation()} />
                  </Popover>
                )}
              </StyledSpace>
            ),
            onClick: () => navigate(employee.myProfileView()),
          },
        ]
      : []),
    {
      key: "userSettings",
      label: <StyledSpace>Settings</StyledSpace>,
      onClick: () => navigate("/preferences"),
    },
    {
      key: "logout",
      label: <StyledSpace>{loading ? "Logging out..." : "Logout"}</StyledSpace>,
      onClick: () => logOut(),
      disabled: loading,
    },
  ];

  return (
    <StyledHeader id="main-header">
      <Space align="center" size={[32, 0]} wrap>
        {!hasCompanies && user?.subscriptionActive && (
          <ApolloProvider client={wsClient}>
            <NotificationBadge onOpenDrawer={() => setOpenNotifications(true)} />
          </ApolloProvider>
        )}

        <Dropdown menu={{ items }} placement="bottomRight" destroyPopupOnHide trigger={["hover", "click"]}>
          <StyledDropDownContentWrapper className="dropdown-content">
            <UserAvatar image={user.profileImageUrl} section="header" />
            <Typography.Text strong style={{ color: COLORS.headerText }}>
              {user.name}
            </Typography.Text>
            <LinkButton icon="down" aria-label="my-settings" />
          </StyledDropDownContentWrapper>
        </Dropdown>
      </Space>
      <NotificationDrawer open={openNotifications} onClose={notificationDrawerCloseHandler} />
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled(AntHeader)`
  &.ant-layout-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 68px;
    padding-inline: 24px;
    background-color: ${COLORS.white};
    box-shadow: 0px 1px 1px ${COLORS.boxShadowColor};
    z-index: 1;
  }
  .notification-link:focus-visible .ant-badge {
    outline: 1px solid ${COLORS.outlineColor};
    outline-offset: 1px;
    border-radius: 2px;
    transition: outline-offset 0s, outline 0s;
  }
`;

const StyledPopoverTitle = styled.span`
  width: 100px;
  text-align: center;
  display: block;
`;

const StyledDropDownContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const StyledBadge = styled(Badge)`
  display: flex;
  margin-right: 6px;
  cursor: pointer;
  &.ant-badge .ant-badge-count {
    background: ${COLORS.dangerColor};
    color: ${COLORS.white};
    font-weight: 600;
    font-size: 11px;
    left: 8px;
    inset-inline-end: unset;
    transform: translate(0%, -50%);
  }
`;

const StyledSpace = styled(Space)`
  font-weight: 500;
  color: ${COLORS.primaryColor};
`;

const StyledBadgeButton = styled(BaseButton)`
  &.ant-btn.badge-btn {
    border: none !important;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
`;
