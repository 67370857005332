import { gql } from "@apollo/client";
import { PAGE_INFO_FRAGMENT, WORKING_CONDITION_FRAGMENT } from "./common";

export const FETCH_USER_COUNT_STATS = gql`
  query fetchUsersCountStats(
    $departmentIds: [ID!]
    $fromDate: ISO8601Date
    $locationIds: [ID!]
    $toDate: ISO8601Date
    $pastMonthDate: ISO8601Date
    $pastYearDate: ISO8601Date
  ) {
    employeesCount: fetchUsersCountStats(
      departmentIds: $departmentIds
      fromDate: $fromDate
      locationIds: $locationIds
      toDate: $toDate
    ) {
      monthYear
      totalUsers
      usersLeftInMonth
      usersStartedInMonth
    }

    pastMonthCount: fetchUsersCountStats(
      departmentIds: $departmentIds
      fromDate: $pastMonthDate
      locationIds: $locationIds
    ) {
      monthYear
      usersLeftInMonth
      usersStartedInMonth
    }

    pastYearCount: fetchUsersCountStats(
      departmentIds: $departmentIds
      fromDate: $pastYearDate
      locationIds: $locationIds
    ) {
      monthYear
      totalUsers
    }
  }
`;

export const FETCH_USERS_GROUP_STATS = gql`
  query fetchUsersGroupStats($grouping: UsersGroupingEnum!, $toDate: ISO8601Date) {
    currentYear: fetchUsersGroupStats(grouping: $grouping) {
      name
      usersCount
    }
    lastYear: fetchUsersGroupStats(grouping: $grouping, toDate: $toDate) {
      name
      usersCount
    }
  }
`;

export const FETCH_USERS_COMPLIANCE_ISSUES_AND_BURNOUT_RADAR = gql`
  query fetchUsersComplianceIssueAndBurnoutRadar($first: Int, $after: String, $userIds: [ID!]) {
    fetchUsersComplianceIssueAndBurnoutRadar: users(first: $first, after: $after, userIds: $userIds) {
      edges {
        cursor
        node {
          id
        }
      }
      nodes {
        id
        name
        profileImageUrl
        holidayBalance {
          holidaysPerYear
          takenDays
          bookedDays
          accruedBalance
        }
        workingCondition {
          ...WorkingConditionFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${WORKING_CONDITION_FRAGMENT}
`;

export const FETCH_USERS_LEAVE_STATS = gql`
  query fetchUsersLeaveStats($calendarYear: Int, $departmentIds: [ID!], $locationIds: [ID!]) {
    fetchUsersLeaveStats(calendarYear: $calendarYear, departmentIds: $departmentIds, locationIds: $locationIds) {
      monthYear
      reason
      totalUsers
    }
  }
`;

export default {
  FETCH_USER_COUNT_STATS,
  FETCH_USERS_GROUP_STATS,
  FETCH_USERS_COMPLIANCE_ISSUES_AND_BURNOUT_RADAR,
  FETCH_USERS_LEAVE_STATS,
};
