import { WorkingCondition, WorkingTimeEnum } from "model/Common";
import { calculateHoursPerWeek, checkBreakCompliance, getSequenceOfDays } from "utils";
import TIME_FORMAT from "constants/timeFormat";
import dayjs from "dayjs";
import { Typography } from "antd";
import styled from "styled-components";
import COLORS from "constants/colors";
import { useParams } from "react-router-dom";
import { useAuthContext } from "contexts";
import { isAdmin } from "model/User";
import EMPLOYEE from "constants/employee";
import { CompliancePopover, EmployeeDataItem } from "./OverviewTab";

type Props = {
  workingCondition?: WorkingCondition;
  hoursPerWeek?: number;
};

const WorkingConditionDetails = ({ workingCondition, hoursPerWeek }: Props) => {
  const { id } = useParams();
  const { user } = useAuthContext();

  const formatTime = (timeString: string) =>
    dayjs(timeString, TIME_FORMAT.timeFormatWith24Hour, true).format(TIME_FORMAT.timeWithPeriodSecondary);

  const checkBreakComplianceIssue = (index?: number) => {
    if (!id || !isAdmin(user) || !workingCondition?.workingDays?.length) {
      return undefined;
    }
    if (workingCondition?.workingTime === WorkingTimeEnum.Fixed) {
      const hoursPerDay = (hoursPerWeek ?? 0) / workingCondition.workingDays.length;
      const lunchBreak = workingCondition.workingDays[0]?.lunchBreak ?? 0;
      return checkBreakCompliance(hoursPerDay, lunchBreak);
    }
    if (index !== undefined && Number.isInteger(index)) {
      const workingDay = workingCondition.workingDays[index];
      const lunchBreak = workingDay.lunchBreak ?? 0;
      const workingHoursPerDay = calculateHoursPerWeek({
        startTimeData: dayjs(workingDay.startTime, TIME_FORMAT.timeFormatWith24Hour, true),
        endTimeData: dayjs(workingDay.endTime, TIME_FORMAT.timeFormatWith24Hour, true),
        daysPerWeek: 1,
        lunchBreak,
      });
      return checkBreakCompliance(workingHoursPerDay, lunchBreak);
    }
    return undefined;
  };

  return (
    <>
      <EmployeeDataItem
        title="Hours per week"
        value={hoursPerWeek}
        isComplianceField={!!id && isAdmin(user) && (hoursPerWeek ?? 0) > 48}
        complianceWarningMsg={EMPLOYEE.complianceIssueMessage.hoursPerWeek}
      />
      {workingCondition?.workingTime === WorkingTimeEnum.Fixed && (
        <>
          <EmployeeDataItem
            title="Break (min)"
            value={
              workingCondition?.workingDays[0].lunchBreak || workingCondition?.workingDays[0].lunchBreak === 0
                ? `${workingCondition?.workingDays[0].lunchBreak}`
                : undefined
            }
            isComplianceField={!!id && isAdmin(user) && !!checkBreakComplianceIssue()}
            complianceWarningMsg={checkBreakComplianceIssue()}
          />
          <EmployeeDataItem
            title="Start time"
            value={
              workingCondition?.workingDays[0].startTime &&
              dayjs(workingCondition?.workingDays[0].startTime, TIME_FORMAT.timeFormatWith24Hour).format(
                TIME_FORMAT.timeWithPeriodSecondary,
              )
            }
          />
          <EmployeeDataItem
            title="End time"
            value={
              workingCondition?.workingDays[0].endTime &&
              dayjs(workingCondition?.workingDays[0].endTime, TIME_FORMAT.timeFormatWith24Hour).format(
                TIME_FORMAT.timeWithPeriodSecondary,
              )
            }
          />
          <EmployeeDataItem
            title="Days per week"
            value={
              workingCondition?.workingDays.length
                ? `${workingCondition?.workingDays.length} (${getSequenceOfDays(
                    workingCondition?.workingDays.map((item) => item.weekDay),
                  )})`
                : ""
            }
          />
        </>
      )}
      {workingCondition?.workingTime === WorkingTimeEnum.Variable && (
        <EmployeeDataItem
          title="Days/time available for work"
          value={workingCondition.workingDays.map((item, index) => {
            const complianceMassage = checkBreakComplianceIssue(index);
            return (
              <WorkingDetailWrapper key={item.weekDay}>
                <WeekDayTitle level={5}>{item.weekDay}</WeekDayTitle>
                <WorkingTimeDetailsWrapper>
                  <WorkingTimeDetails>{`Start time: ${formatTime(item.startTime)}`}</WorkingTimeDetails>
                  <WorkingTimeDetails>{`End time: ${formatTime(item.endTime)}`}</WorkingTimeDetails>
                  {!!id && !!complianceMassage ? (
                    <WorkingTimeDetails>
                      <span className="d-flex align-items-center gap-1">
                        Break(min): <span className="compliance">{item.lunchBreak}</span>
                        <CompliancePopover complianceWarningMsg={complianceMassage} />
                      </span>
                    </WorkingTimeDetails>
                  ) : (
                    <WorkingTimeDetails>{`Break(min): ${item.lunchBreak}`}</WorkingTimeDetails>
                  )}
                </WorkingTimeDetailsWrapper>
              </WorkingDetailWrapper>
            );
          })}
        />
      )}
    </>
  );
};

export default WorkingConditionDetails;

const WorkingDetailWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.borderLight};
  padding: 11px 0px;
  &:first-child {
    padding: 9px 0px;
  }
`;

const WorkingTimeDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;

  & div:last-child {
    max-width: 110px;
    min-width: 76px;
  }
`;

const WeekDayTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 12px;
  }
`;

const WorkingTimeDetails = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  width: 100%;

  & span.compliance {
    color: ${COLORS.hocColor};
  }
`;
