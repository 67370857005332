import { Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { DefaultButton } from "components/Buttons";
import COLORS from "constants/colors";
import { useNotificationContext } from "contexts/NotificationProvider";
import { NotificationType, notificationsFilteredData, OtherUpdateData } from "model/Notification";
import { useMemo } from "react";
import styled from "styled-components";
import storage from "utils/storage";

const OtherUpdates = () => {
  const { refresh, otherNotificationData } = useNotificationContext();

  const data = useMemo(
    () => (otherNotificationData ? notificationsFilteredData(otherNotificationData, NotificationType.Other) : []),
    [otherNotificationData],
  );

  const columns: ColumnsType<OtherUpdateData> = [
    {
      key: "id",
      dataIndex: "name",
      width: 300,
    },
    {
      dataIndex: "message",
    },
    {
      title: "",
      render: (_, r) => (
        <DefaultButton
          onClick={() => {
            storage.addReadNotification(r.id, NotificationType.Other);
            refresh();
          }}
        >
          Ok
        </DefaultButton>
      ),
      width: 60,
    },
  ];
  return (
    <StyledWrapper>
      <Space direction="vertical" size={[0, 22]} className="w-100 section-box-shadow">
        <Typography.Title level={1} className="m-0 table-title">
          Other updates
        </Typography.Title>
        <Table
          data-testid="otherUpdateAlert"
          columns={columns}
          dataSource={data}
          pagination={false}
          showHeader={false}
          rowKey="id"
        />
      </Space>
    </StyledWrapper>
  );
};

export default OtherUpdates;

const StyledWrapper = styled.div`
  padding: 24px 20px 24px 27px;
  border-bottom: 1px solid ${COLORS.borderLight};

  .ant-table-wrapper {
    border: 1px solid ${COLORS.borderLight};
  }

  .ant-table .ant-table-tbody tr td:first-child,
  .ant-table .ant-table-thead tr th:first-child,
  .ant-table .ant-table-thead tr td:first-child {
    padding-left: 17px;
  }
`;
