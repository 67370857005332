import { useMutation } from "@apollo/client";
import { Modal, Space, Typography } from "antd";
import { DefaultButton, PrimaryButton } from "components/Buttons";
import { Container } from "components/core";
import { useModal } from "components/modal";
import COLORS from "constants/colors";
import { useNotificationContext } from "contexts/NotificationProvider";
import { SendDocumentReminderResponse } from "model/Document";
import { NotificationType } from "model/Notification";
import { useState } from "react";
import { useNotify } from "services/api";
import { SEND_DOCUMENT_REMINDER } from "services/graphql/document";
import styled from "styled-components";
import { expectNumber, getRelativeDate } from "utils";
import storage from "utils/storage";
import DocumentActionPendingListModal from "./DocumentActionPendingListModal";

const DocumentsActioned = () => {
  const { employeeActionedData: data, refresh } = useNotificationContext();
  const notify = useNotify();
  const [actionRequired, setActionRequired] = useState<string>();
  const [documentId, setDocumentId] = useState<string>();
  const {
    isModalVisible: isDescriptionModalVisible,
    hideModal: hideDescriptionModal,
    showModal: showDescriptionModal,
  } = useModal();

  const {
    isModalVisible: isActionPendingModalVisible,
    hideModal: hideActionPendingModal,
    showModal: showActionPendingModal,
  } = useModal();

  const [sendReminder, { loading }] = useMutation<SendDocumentReminderResponse>(SEND_DOCUMENT_REMINDER, {
    onCompleted: (response) => {
      if (response?.sendDocumentReminder?.success) {
        notify.success({ message: "Send reminder to users successfully." });
      }
    },
  });

  return (
    <div>
      <StyledDocumentContainerTitle level={1} className="m-0 table-title">
        Documents actioned
      </StyledDocumentContainerTitle>
      <Space direction="vertical" size={[0, 24]} className="w-100">
        {data.map((item) => (
          <StyledDocumentWrapper key={item.id}>
            <StyledDocumentAlertMessage>
              <span>
                The <StyledDocumentTitle data-testid="documentName">{item.name}</StyledDocumentTitle> document has a
                deadline {item?.deadlineDate ? getRelativeDate(item.deadlineDate) : "/"}
              </span>
              <span>
                and{" "}
                <StyledDocumentTitle
                  className="count"
                  data-testid="documentActionPending"
                  onClick={() => {
                    setDocumentId(item.id);
                    showActionPendingModal();
                  }}
                >
                  {item.actionPending}
                </StyledDocumentTitle>{" "}
                employees have not actioned it.
              </span>
            </StyledDocumentAlertMessage>
            <div className="py-4">
              <Space size={[16, 0]}>
                <PrimaryButton onClick={() => sendReminder({ variables: { documentId: item.id } })} loading={loading}>
                  Send reminder
                </PrimaryButton>
                <DefaultButton
                  onClick={() => {
                    setActionRequired(item.action);
                    showDescriptionModal();
                  }}
                >
                  View details
                </DefaultButton>
                <DefaultButton
                  onClick={() => {
                    storage.addReadNotification(expectNumber(item.id), NotificationType.Document);
                    refresh();
                  }}
                >
                  Ignore
                </DefaultButton>
              </Space>
            </div>
          </StyledDocumentWrapper>
        ))}
      </Space>
      <Modal
        title="Details"
        open={isDescriptionModalVisible}
        onCancel={hideDescriptionModal}
        footer={null}
        width={519}
        centered
        destroyOnClose
      >
        <StyledContainer>{actionRequired}</StyledContainer>
      </Modal>
      <DocumentActionPendingListModal
        hideModal={hideActionPendingModal}
        isModalVisible={isActionPendingModalVisible}
        id={documentId}
      />
    </div>
  );
};

export default DocumentsActioned;

const StyledDocumentContainerTitle = styled(Typography.Title)`
  padding: 24px 20px 24px 27px;
`;

const StyledDocumentWrapper = styled.div`
  padding: 0px 20px 0px 27px;
  border-bottom: 1px solid ${COLORS.borderLight};
`;

const StyledContainer = styled(Container)`
  background-color: ${COLORS.defaultColor};
  padding: 28px 38px;
`;

const StyledDocumentAlertMessage = styled(Typography.Text)`
  color: ${COLORS.inputFieldTextColor};
  display: flex;
  flex-direction: column;
`;

const StyledDocumentTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.tertiary};

  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
  &.count {
    cursor: pointer;
  }
`;
