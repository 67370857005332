import { useSubscription } from "@apollo/client";
import EMPLOYEE from "constants/employee";
import { useFetchRequestList } from "contexts/LeaveRequestListProvider";
import { useNotificationContext } from "contexts/NotificationProvider";
import { removeExtensionFromName } from "features/employee/leave";
import {
  BirthdayComingInResponse,
  DocumentSharedNotificationResponse,
  FinishingInResponse,
  LeaveNotificationMsg,
  LeaveRequestApprovedResponse,
  LeaveRequestCreatedResponse,
  LeaveRequestDeclinedResponse,
  OtherNotificationMsg,
  ProbationaryPeriodEndingResponse,
  StartingInResponse,
  WorkAnniversaryResponse,
} from "model/Notification";
import { useNotify } from "services/api";
import {
  BIRTHDAY_COMING_IN,
  DOCUMENT_SHARED,
  FINISHING_IN,
  LEAVE_SUBSCRIPTION_APPROVED,
  LEAVE_SUBSCRIPTION_CREATED,
  LEAVE_SUBSCRIPTION_DECLINED,
  PROBATIONARY_PERIOD_ENDING,
  STARTING_IN,
  WORK_ANNIVERSARY,
} from "services/graphql/notification";

export const useNotifications = (onNotification: () => void) => {
  const { refetchList } = useFetchRequestList();
  const { refetchEmployeePending, refetchMyNotificationLeave, refetchOtherNotification, myDocumentReviewRefetch } =
    useNotificationContext();
  const notify = useNotify();

  useSubscription<LeaveRequestApprovedResponse>(LEAVE_SUBSCRIPTION_APPROVED, {
    fetchPolicy: "no-cache",
    onData: ({ data: { data } }) => {
      if (data?.leaveRequestApproved?.leaveRequest) {
        onNotification();
        refetchList();
        refetchEmployeePending();
        refetchMyNotificationLeave();
        const {
          fromDate,
          toDate,
          user: { name },
        } = data.leaveRequestApproved.leaveRequest;
        const message = (EMPLOYEE.messages.leaveApproved as LeaveNotificationMsg)(fromDate, toDate, name);
        notify.info({ message });
      }
    },
  });

  useSubscription<LeaveRequestCreatedResponse>(LEAVE_SUBSCRIPTION_CREATED, {
    fetchPolicy: "no-cache",
    onData: ({ data: { data } }) => {
      if (data?.leaveRequestCreated?.leaveRequest) {
        onNotification();
        refetchList();
        refetchEmployeePending();
        const {
          fromDate,
          toDate,
          user: { name },
        } = data.leaveRequestCreated.leaveRequest;
        const message = (EMPLOYEE.messages.leaveCreated as LeaveNotificationMsg)(fromDate, toDate, name);
        notify.info({ message });
      }
    },
  });

  useSubscription<LeaveRequestDeclinedResponse>(LEAVE_SUBSCRIPTION_DECLINED, {
    fetchPolicy: "no-cache",
    onData: ({ data: { data } }) => {
      if (data?.leaveRequestDeclined?.leaveRequest) {
        onNotification();
        refetchList();
        refetchEmployeePending();
        refetchMyNotificationLeave();
        const {
          fromDate,
          toDate,
          user: { name },
        } = data.leaveRequestDeclined.leaveRequest;
        const message = (EMPLOYEE.messages.leaveDeclined as LeaveNotificationMsg)(fromDate, toDate, name);
        notify.info({ message });
      }
    },
  });

  useSubscription<BirthdayComingInResponse>(BIRTHDAY_COMING_IN, {
    fetchPolicy: "no-cache",
    onData: ({ data: { data } }) => {
      if (data?.birthdayComingIn?.user) {
        onNotification();
        refetchOtherNotification();
        const { name, dateOfBirth } = data.birthdayComingIn?.user;
        const message = (EMPLOYEE.messages.birthdaysThisWeek as OtherNotificationMsg)(dateOfBirth, name);
        notify.info({ message });
      }
    },
  });

  useSubscription<StartingInResponse>(STARTING_IN, {
    fetchPolicy: "no-cache",
    onData: ({ data: { data } }) => {
      if (data?.startingIn?.user) {
        onNotification();
        refetchOtherNotification();
        const { name, startedAt } = data.startingIn?.user;
        const message = (EMPLOYEE.messages.startingThisWeek as OtherNotificationMsg)(startedAt, name);
        notify.info({ message });
      }
    },
  });

  useSubscription<FinishingInResponse>(FINISHING_IN, {
    fetchPolicy: "no-cache",
    onData: ({ data: { data } }) => {
      if (data?.finishingIn?.user) {
        onNotification();
        refetchOtherNotification();
        const { name, leavingRequest } = data.finishingIn?.user;
        const message = (EMPLOYEE.messages.finishingIn as OtherNotificationMsg)(leavingRequest.leavingAt, name);
        notify.info({ message });
      }
    },
  });

  useSubscription<ProbationaryPeriodEndingResponse>(PROBATIONARY_PERIOD_ENDING, {
    fetchPolicy: "no-cache",
    onData: ({ data: { data } }) => {
      if (data?.probationaryPeriodEnding?.user) {
        onNotification();
        refetchOtherNotification();
        const { name, startedAt } = data.probationaryPeriodEnding?.user;
        const message = (EMPLOYEE.messages.probationaryPeriodEndingThisWeek as OtherNotificationMsg)(startedAt, name);
        notify.info({ message });
      }
    },
  });

  useSubscription<WorkAnniversaryResponse>(WORK_ANNIVERSARY, {
    fetchPolicy: "no-cache",
    onData: ({ data: { data } }) => {
      if (data?.workAnniversary?.user) {
        onNotification();
        refetchOtherNotification();
        const { name, startedAt } = data.workAnniversary?.user;
        const message = (EMPLOYEE.messages.workAnniversaryThisWeek as OtherNotificationMsg)(startedAt, name);
        notify.info({ message });
      }
    },
  });

  useSubscription<DocumentSharedNotificationResponse>(DOCUMENT_SHARED, {
    fetchPolicy: "no-cache",
    onData: ({ data: { data } }) => {
      if (data?.documentShared?.document) {
        onNotification();
        myDocumentReviewRefetch();
        const { attachmentName } = data.documentShared?.document;
        notify.info({
          message: `${removeExtensionFromName(attachmentName)} document is shared with you.`,
        });
      }
    },
  });
};

export default useNotifications;
