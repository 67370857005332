import EMPLOYEE from "constants/employee";

export function includesInArray<T>(arr: T[] | undefined, value: T | T[]): boolean {
  if (arr && !Array.isArray(value)) {
    return arr.includes(value);
  }
  if (arr && Array.isArray(value)) {
    return value.every((value: T) => arr.includes(value));
  }
  return false;
}
export function checkPermission(permissionsList: string[] | undefined, permissions: (string | string[])[] | string) {
  if (Array.isArray(permissions)) {
    const resultArray = permissions.map((element) => includesInArray(permissionsList, element));
    return includesInArray(resultArray, true);
  }
  return includesInArray(permissionsList, permissions);
}

export function checkValidFileExtension(filename: string, extension: string | string[]) {
  if (Array.isArray(extension)) {
    // eslint-disable-next-line no-bitwise
    return extension.includes(filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2).toLocaleLowerCase());
  }
  // eslint-disable-next-line no-bitwise
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2).toLocaleLowerCase() === extension;
}

export const checkBreakCompliance = (hoursPerDay: number, lunchBreak: number): string | undefined => {
  if (hoursPerDay > 6 && lunchBreak < 30) {
    return EMPLOYEE.complianceIssueMessage.break.max;
  }
  if (hoursPerDay >= 4.5 && lunchBreak < 15) {
    return EMPLOYEE.complianceIssueMessage.break.min;
  }
  return undefined;
};

export default { includesInArray, checkPermission, checkValidFileExtension, checkBreakCompliance };
