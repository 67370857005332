import { LoadingPage } from "components/core";
import COLORS from "constants/colors";
import { useMemo } from "react";

type Props = {
  notLoggedInUsers: number;
  loading: boolean;
};

const NotLoggedInSvg = ({ notLoggedInUsers, loading }: Props) => {
  const { circumference, progressArc } = useMemo(() => {
    const radius = 91.5;
    const circumference = Math.PI * radius;
    const progressArc = (notLoggedInUsers / 100) * circumference;

    return { circumference, progressArc };
  }, [notLoggedInUsers]);

  if (loading) return <LoadingPage />;

  return (
    <svg width={244} height={147} viewBox="0 0 244 147" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <path
          d="M234 139c0-32.356-11.8-63.388-32.804-86.267C180.192 29.853 151.704 17 122 17S63.808 29.854 42.804 52.733C21.8 75.613 10 106.644 10 139z"
          fill="url(#b)"
        />
      </g>

      <path
        d="M31 131c0-24.4 9.64-47.8 26.8-65.054C74.96 48.693 98.233 39 122.5 39s47.541 9.693 64.7 26.946C204.36 83.2 214 106.6 214 131"
        stroke="url(#d)"
        strokeWidth={10}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={0}
      />
      <path
        d="M31 131c0-24.4 9.64-47.8 26.8-65.054C74.96 48.693 98.233 39 122.5 39s47.541 9.693 64.7 26.946C204.36 83.2 214 106.6 214 131"
        stroke="url(#c)"
        strokeWidth={10}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={0}
      >
        <animate
          attributeName="stroke-dasharray"
          from={`0 ${circumference}`}
          to={`${progressArc} ${circumference}`}
          dur="0.5s"
          fill="freeze"
        />
      </path>

      <path
        d="M192 131c0-38.108-30.892-69-69-69-38.107 0-69 30.892-69 69"
        stroke={COLORS.innerDotedBorderColor}
        strokeDasharray="1 4"
      />

      <g
        mask="url(#e)"
        stroke={COLORS.innerIconColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M119.719 92.922a3.281 3.281 0 1 1 6.562 0 3.281 3.281 0 0 1-6.562 0m0 33.989v-5.689a4.1 4.1 0 0 0-2.022-3.535l-.14-.082a4.1 4.1 0 0 1-2.022-3.536v-7.284c0-4.1 3.324-5.743 7.424-5.743s7.424 1.643 7.424 5.743v7.308a4.1 4.1 0 0 1-1.991 3.517l-.119.071a4.1 4.1 0 0 0-1.992 3.517v5.713m-19.359-30.708a3.281 3.281 0 1 1 6.562 0 3.281 3.281 0 0 1-6.562 0m6.562 30.708v-4.294m-6.562 4.294v-4.294a4.1 4.1 0 0 0-1.641-3.282 4.1 4.1 0 0 1-1.64-3.281v-6.973c0-3.533 2.864-4.716 6.398-4.716m29.039-8.162a3.281 3.281 0 1 0-6.562 0 3.281 3.281 0 0 0 6.562 0m-6.562 30.708v-4.294m6.562 4.294v-4.294c0-1.291.608-2.507 1.641-3.282a4.1 4.1 0 0 0 1.64-3.281v-6.973c0-3.533-2.864-4.716-6.398-4.716" />
      </g>

      <defs>
        <linearGradient id="b" x1={122} y1={17} x2={122} y2={139} gradientUnits="userSpaceOnUse">
          <stop stopColor={COLORS.white} />
          <stop offset={1} stopColor={COLORS.outerContainerGradientColor} />
        </linearGradient>
        <linearGradient id="c" x1={31} y1={85} x2={160} y2={101} gradientUnits="userSpaceOnUse">
          <stop stopColor={COLORS.loggedInStartGradientColor} />
          <stop offset={1} stopColor={COLORS.loggedInEndGradientColor} />
        </linearGradient>
        <linearGradient id="d" x1={206.491} y1={119.098} x2={214} y2={119.094} gradientUnits="userSpaceOnUse">
          <stop stopColor={COLORS.notLoggedInStartGradientColor} />
          <stop offset={1} stopColor={COLORS.notLoggedInEndGradientColor} />
        </linearGradient>
        <filter id="a" x="0" y="0" width="244" height="142" filterUnits="userSpaceOnUse">
          <feDropShadow
            dx="0"
            dy="-7"
            stdDeviation="5"
            floodColor={COLORS.outerContainerBoxShadow}
            floodOpacity=".34"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default NotLoggedInSvg;
