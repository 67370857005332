import { Space } from "antd";
import COLORS, { ColorType } from "constants/colors";
import { PeopleOnLeaveChartData, PeopleOnLeaveTypeEnum, peopleOnLeaveTypeLabel } from "model/Insights";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import { enumValues } from "utils/misc";

type Props = {
  data: PeopleOnLeaveChartData[];
};

const PeopleOnLeaveChart = ({ data }: Props) => (
  <Space direction="vertical" size={[0, 31]} className="w-100">
    <ResponsiveContainer width="100%" height={230}>
      <BarChart data={data} margin={{ left: 0, bottom: 10, right: 23 }} barSize={12}>
        <XAxis dataKey="month" axisLine={false} tickLine={false} minTickGap={0} tickMargin={14} />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 10, fontWeight: 500, color: COLORS.noColor }}
          tickMargin={14}
          tickCount={6}
          domain={[0, "dataMax"]}
        />
        <CartesianGrid strokeDasharray="3 0" stroke={COLORS.xAxisStrokeColor} vertical={false} />
        {enumValues(PeopleOnLeaveTypeEnum).map((l) => (
          <Bar stackId="month" key={l} dataKey={l} fill={COLORS[`${l.toLowerCase()}LeaveBar` as ColorType]} />
        ))}
      </BarChart>
    </ResponsiveContainer>
    <StyledLegendWrapper>
      {enumValues(PeopleOnLeaveTypeEnum).map((l) => (
        <StyledLegend type={l} key={l}>
          {peopleOnLeaveTypeLabel[l]}
        </StyledLegend>
      ))}
    </StyledLegendWrapper>
  </Space>
);

export default PeopleOnLeaveChart;

const StyledLegendWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  justify-content: center;
  margin: 0px 30px 17px 24px;
`;

const StyledLegend = styled.div<{ type: PeopleOnLeaveTypeEnum }>`
  font-size: 12px;
  color: ${COLORS.inputFieldTextColor};
  display: flex;
  align-items: center;
  &:before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 1px;
    margin-right: 6px;
    margin-bottom: 1px;
  }
  &:before {
    background: ${({ type }) => COLORS[`${type.toLowerCase()}LeaveBar` as ColorType]};
  }
`;
