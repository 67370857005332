import { Image, Space, Typography } from "antd";
import styled from "styled-components";
import COLORS from "constants/colors";
import EmptyNotificationSvg from "../../../assets/images/empty-notification.svg";

const EmptyNotification = () => (
  <StyledWrapper>
    <Space direction="vertical" size={[0, 24]} align="center">
      <Image src={EmptyNotificationSvg} preview={false} />
      <StyledTitle level={3}>You’re all caught up on your notifications.</StyledTitle>
      <StyledDescription level={4}>
        There are currently no new notifications to display on this tab. Keep an eye out for important alerts in the
        future.
      </StyledDescription>
    </Space>
  </StyledWrapper>
);

export default EmptyNotification;

const StyledWrapper = styled.div`
  width: 418px;
  margin: 12% auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 16px;
  }
`;

const StyledDescription = styled(Typography.Title)`
  &.ant-typography {
    font-weight: 500;
    color: ${COLORS.headerText};
    text-align: center;
  }
`;
