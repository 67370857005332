import { BillingHistory, CurrentPackage, DefaultCardDetails, PriceType, PromotionCode } from "model/AdminSetting";
import { useReducer } from "react";
import { enumValues } from "utils/misc";
import { IsMutationCall } from "./BillingForm";

export enum Actions {
  IsProcessing = "IS_PROCESSING",
  IsMutationCall = "IS_MUTATION_CALL",
  CurrentPackage = "CURRENT_PACKAGE",
  DefaultCardDetails = "DEFAULT_CARD_DETAILS",
  InvoicesData = "INVOICES_DATA",
  IsAddressElementCall = "IS_ADDRESS_ELEMENT_CALL",
  PromotionCode = "PROMOTION_CODE",
  PriceData = "PRICE_DATA",
  PromoCode = "PROMO_CODE",
  IsSaveDisabled = "IS_SAVE_DISABLE",
}

const stateKeys: Record<Actions, string> = {
  [Actions.IsProcessing]: "isProcessing",
  [Actions.IsMutationCall]: "isMutationCall",
  [Actions.CurrentPackage]: "currentPackage",
  [Actions.DefaultCardDetails]: "defaultCardDetails",
  [Actions.InvoicesData]: "invoicesData",
  [Actions.IsAddressElementCall]: "isAddressElementCall",
  [Actions.PromotionCode]: "promotionCode",
  [Actions.PriceData]: "priceData",
  [Actions.PromoCode]: "promoCode",
  [Actions.IsSaveDisabled]: "isSaveDisabled",
};

export interface StateData {
  isProcessing: boolean;
  isMutationCall: IsMutationCall;
  currentPackage?: CurrentPackage;
  defaultCardDetails?: DefaultCardDetails;
  invoicesData?: BillingHistory[];
  isAddressElementCall: boolean;
  promotionCode?: string;
  priceData?: PriceType[];
  promoCode?: PromotionCode;
  isSaveDisabled: boolean;
}

type PayloadType =
  | boolean
  | IsMutationCall
  | CurrentPackage
  | DefaultCardDetails
  | BillingHistory[]
  | string
  | PriceType[]
  | PromotionCode
  | undefined;

export interface ActionArg {
  type: Actions;
  payload?: PayloadType;
}

export const initialState: StateData = {
  isProcessing: false,
  isMutationCall: {},
  currentPackage: undefined,
  defaultCardDetails: undefined,
  invoicesData: undefined,
  isAddressElementCall: false,
  promotionCode: undefined,
  priceData: undefined,
  promoCode: undefined,
  isSaveDisabled: true,
};

const reducer = (state: StateData, action: ActionArg) => {
  if (enumValues(Actions).includes(action.type)) {
    return { ...state, [stateKeys[action.type]]: action.payload };
  }
  return state;
};

export const useBilling = () => {
  const [billingState, dispatch] = useReducer(reducer, initialState);

  return { billingState, dispatch };
};

export default useBilling;
