import { Typography } from "antd";
import COLORS from "constants/colors";
import styled from "styled-components";

const BadgeCount = styled(Typography.Title)`
  &.ant-typography {
    background: ${COLORS.hocColor};
    border-radius: 12px;
    width: 22px;
    height: 22px;
    font-size: 11px;
    color: ${COLORS.white};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 9px;
    margin-bottom: 0px;
    opacity: 1;
    margin-top: 0px !important;
  }
`;

export default BadgeCount;
