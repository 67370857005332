import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import { useNotificationContext } from "contexts/NotificationProvider";
import ApproveLeaveTable from "./ApproveLeaveTable";
import DocumentsActioned from "./DocumentsActioned";
import OtherUpdates from "./OtherUpdates";
import EmptyNotification from "../EmptyNotification";

const EmployeeNotifications = () => {
  const { employeeDocumentNotification } = PERMISSION;
  const { employeeActionedData, employeeNotificationCount, employeePendingData, otherNotificationData } =
    useNotificationContext();
  const { hasEmployeeDocumentNotification } = useCheckPermission({ employeeDocumentNotification });

  if (!employeeNotificationCount) {
    return <EmptyNotification />;
  }

  return (
    <div>
      {employeePendingData!.length > 0 && <ApproveLeaveTable />}
      {otherNotificationData!.length > 0 && <OtherUpdates />}
      {hasEmployeeDocumentNotification && employeeActionedData!.length > 0 && <DocumentsActioned />}
    </div>
  );
};

export default EmployeeNotifications;
