import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import UserAvatar from "components/UserAvatar/UserAvatar";
import COLORS from "constants/colors";
import TABLE from "constants/table";
import { BurnoutRadarData } from "model/Insights";
import styled from "styled-components";

type Props = {
  data: BurnoutRadarData[];
  loading: boolean;
  type?: "default" | "list";
};
const BurnoutRadarTable = ({ data, loading, type = "default" }: Props) => {
  const columns: ColumnsType<BurnoutRadarData> = [
    {
      title: TABLE.burnoutRadarTableHeaders[0],
      dataIndex: "name",
      width: "35%",
      align: "left",
      render: (_, { name, profileImageUrl }) => (
        <div className="d-flex">
          <UserAvatar section="header" image={profileImageUrl} />
          <StyledName>{name}</StyledName>
        </div>
      ),
    },
    {
      title: TABLE.burnoutRadarTableHeaders[1],
      dataIndex: "taken",
      align: "center",
    },
    {
      title: TABLE.burnoutRadarTableHeaders[2],
      dataIndex: "booked",
      align: "center",
    },
    {
      title: TABLE.burnoutRadarTableHeaders[3],
      dataIndex: "accrued",
      align: "center",
    },
    {
      title: TABLE.burnoutRadarTableHeaders[4],
      dataIndex: "remaining",
      align: "center",
    },
  ];

  return (
    <StyledTable>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered={false}
        loading={loading}
        scroll={{
          scrollToFirstRowOnChange: false,
          y: type === "list" ? 475 : undefined,
        }}
      />
    </StyledTable>
  );
};

export default BurnoutRadarTable;

const StyledName = styled(Typography.Text)`
  color: ${COLORS.inputFieldTextColor};
  font-size: 13px;
  padding-left: 11px;
`;

const StyledTable = styled.div`
  & .ant-table .ant-table-tbody tr td {
    font-size: 13px;
  }

  & .ant-table .ant-table-tbody tr td:last-child {
    font-weight: 700;
  }
`;
