import { useState } from "react";
import { Space, Typography } from "antd";
import { Container } from "components/core";
import { InputDropDown } from "components/Inputs";
import COLORS from "constants/colors";
import { useDepartments, useLocations } from "hooks";
import styled from "styled-components";
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import { FETCH_USERS_LEAVE_STATS } from "services/graphql/insights";
import {
  FetchUsersLeaveStatsResponse,
  LabelFilter,
  PeopleOnLeaveChartData,
  PeopleOnLeaveTypeEnum,
} from "model/Insights";
import DATE_FORMAT from "constants/dateFormat";
import LEAVE from "constants/leave";
import { enumValues } from "utils/misc";
import PeopleOnLeaveChart from "./PeopleOnLeaveChart";

const PeopleOnLeave = () => {
  const {
    departments,
    loading: departmentsLoading,
    handleDropdownScroll: handleDepartmentDropdownScroll,
  } = useDepartments({});
  const { locations, loading: locationsLoading, handleDropdownScroll: handleLocationDropdownScroll } = useLocations({});
  const [labelFilter, setLabelFilter] = useState<LabelFilter>();
  const [dateFilter, setDaterFilter] = useState<number>();
  const [data, setData] = useState<PeopleOnLeaveChartData[]>(LEAVE.peopleOnLeaveChartDefaultData);

  const labelFilterOnChangeHandler = (value: string, key: "departments" | "locations") => {
    setLabelFilter((prev) => ({ ...prev, [key]: value === "all" ? undefined : value }));
  };

  useQuery<FetchUsersLeaveStatsResponse>(FETCH_USERS_LEAVE_STATS, {
    fetchPolicy: "no-cache",
    variables: {
      calendarYear: dateFilter || dayjs().year(),
      departmentIds: labelFilter?.departments && [labelFilter?.departments],
      locationIds: labelFilter?.locations && [labelFilter?.locations],
    },
    onCompleted: (response) => {
      const leaveReason = enumValues(PeopleOnLeaveTypeEnum) as string[];
      const modifyData = response.fetchUsersLeaveStats.reduce((acc, { monthYear, reason, totalUsers }) => {
        const month = dayjs(monthYear, DATE_FORMAT.monthAndYearSecondary).format(DATE_FORMAT.shortMonth);

        const monthData = acc.map((item) => {
          if (item.month === month) {
            if (leaveReason.includes(reason)) {
              return { ...item, [reason]: totalUsers };
            }
            return { ...item, [PeopleOnLeaveTypeEnum.Other]: item[PeopleOnLeaveTypeEnum.Other] + totalUsers };
          }
          return item;
        });
        return monthData;
      }, LEAVE.peopleOnLeaveChartDefaultData);

      setData(modifyData);
    },
  });

  return (
    <StyledContainer data-testid="peopleOnLeave">
      <StyledHeader wrap>
        <Typography.Title level={4} className="m-0">
          Number of people on leave
        </Typography.Title>

        <Space size={[7, 0]}>
          <StyledInputDropDown
            options={[{ value: "all", label: "All departments" }, ...departments]}
            loading={departmentsLoading}
            defaultValue="All departments"
            data-testid="departments"
            showSearch={false}
            optionLabelProp="label"
            onPopupScroll={handleDepartmentDropdownScroll}
            popupMatchSelectWidth={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => labelFilterOnChangeHandler(value, "departments")}
          />
          <StyledInputDropDown
            options={[{ value: "all", label: "All locations" }, ...locations]}
            loading={locationsLoading}
            defaultValue="All locations"
            data-testid="locations"
            showSearch={false}
            optionLabelProp="label"
            popupMatchSelectWidth={false}
            onPopupScroll={handleLocationDropdownScroll}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => labelFilterOnChangeHandler(value, "locations")}
          />
          <StyledInputDropDown
            options={Array.from({ length: dayjs().year() - 2021 + 1 }, (_, i) => ({
              label: 2021 + i,
              value: 2021 + i,
            }))}
            defaultValue={dayjs().year()}
            data-testid="year"
            showSearch={false}
            optionLabelProp="value"
            popupMatchSelectWidth={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => setDaterFilter(value)}
          />
        </Space>
      </StyledHeader>
      <PeopleOnLeaveChart data={data || LEAVE.peopleOnLeaveChartDefaultData} />
    </StyledContainer>
  );
};

export default PeopleOnLeave;

const StyledContainer = styled(Container)`
  height: 100%;
  padding: 0px;
  box-shadow: 1px 1px 0px 0px ${COLORS.lightBoxShadowColor};
`;

const StyledHeader = styled(Space)`
  justify-content: space-between;
  width: 100%;
  padding: 21px 23px 24px 20px;
`;

const StyledInputDropDown = styled(InputDropDown)`
  &.ant-select.ant-select-single {
    height: 19px;

    & .ant-select-selector {
      border: none;
      padding: 0px 11px 0px 0px;

      & .ant-select-selection-search-input {
        height: 19px;
      }

      & .ant-select-selection-item {
        font-size: 13px;
        color: ${COLORS.disabledColor};
        line-height: 19px;
      }
    }
  }
`;
