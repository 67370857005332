import { Typography } from "antd";
import { Container } from "components/core";
import styled from "styled-components";
import { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useState } from "react";
import {
  DocumentDataState,
  DocumentRefetch,
  Documents,
  DocumentVisibilityType,
  FetchDocumentArg,
  FetchDocumentsResponse,
} from "model/Document";
import { useAuthContext } from "contexts";
import { useParams } from "react-router-dom";
import { FETCH_DOCUMENTS } from "services/graphql/document";
import { useLazyQuery } from "@apollo/client";
import TABLE from "constants/table";
import DocumentTable from "./DocumentTable";

const DocumentTab = forwardRef<DocumentRefetch>((_, ref) => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const userId = id || user?.id;

  const [data, setDocumentsData] = useState<DocumentDataState>();

  const modifyData = (userDocumentData: Documents[]) => {
    const modifyData = userDocumentData.reduce(
      (acc, document) => {
        if (document.visibilityType === DocumentVisibilityType.Individual) {
          acc.employeeDocuments.push(document);
        }
        if (document.visibilityType === DocumentVisibilityType.Many) {
          acc.companyDocuments.push(document);
        }
        return acc;
      },
      { employeeDocuments: [], companyDocuments: [] } as DocumentDataState,
    );
    setDocumentsData(modifyData);
  };

  const [fetchDocuments, { loading }] = useLazyQuery<FetchDocumentsResponse>(FETCH_DOCUMENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      const { nodes, pageInfo } = response?.documents;
      if (pageInfo?.hasNextPage) {
        fetchEmployeeDocument({ accumulated: [...nodes], cursor: pageInfo.endCursor }).then((data) => modifyData(data));
      } else {
        modifyData([...nodes]);
      }
    },
  });

  const fetchEmployeeDocument = useCallback(
    async ({ cursor, accumulated }: FetchDocumentArg) => {
      const result = await fetchDocuments({
        variables: {
          first: TABLE.maxRowPerPage,
          after: cursor || undefined,
          userId,
        },
      });
      if (result.data) {
        const { nodes, pageInfo } = result?.data?.documents;
        const newData = [...(accumulated ?? []), ...nodes];
        if (pageInfo?.hasNextPage) {
          return fetchEmployeeDocument({ accumulated: newData, cursor: pageInfo.endCursor });
        }
        return newData;
      }
      return [];
    },
    [fetchDocuments, userId],
  );

  useLayoutEffect(() => {
    if (userId) {
      fetchDocuments({ variables: { first: TABLE.maxRowPerPage, userId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    documentRefetch() {
      fetchDocuments({ variables: { first: TABLE.maxRowPerPage, userId } });
    },
  }));

  return (
    <StyledWrapper>
      <Container className="p-0 section-box-shadow">
        <Typography.Title level={2} className="p-4 m-0 table-title">
          This employee’s documents
        </Typography.Title>
        <DocumentTable tableFor="employeeDocuments" data={data?.employeeDocuments ?? []} loading={loading} />
      </Container>
      <Container className="p-0 mt-4 section-box-shadow">
        <Typography.Title level={2} className="p-4 m-0 table-title">
          Company documents
        </Typography.Title>
        <DocumentTable tableFor="companyDocuments" data={data?.companyDocuments ?? []} loading={loading} />
      </Container>
    </StyledWrapper>
  );
});

export default DocumentTab;

const StyledWrapper = styled.div`
  .ant-table-column-sorter {
    display: none;
  }
`;
