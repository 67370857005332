import { gql } from "@apollo/client";

export const LEAVE_SUBSCRIPTION_APPROVED = gql`
  subscription {
    leaveRequestApproved {
      leaveRequest {
        fromDate
        toDate
        user {
          name
        }
      }
    }
  }
`;
export const LEAVE_SUBSCRIPTION_DECLINED = gql`
  subscription {
    leaveRequestDeclined {
      leaveRequest {
        fromDate
        toDate
        user {
          name
        }
      }
    }
  }
`;
export const LEAVE_SUBSCRIPTION_CREATED = gql`
  subscription {
    leaveRequestCreated {
      leaveRequest {
        fromDate
        toDate
        user {
          name
        }
      }
    }
  }
`;
export const BIRTHDAY_COMING_IN = gql`
  subscription {
    birthdayComingIn {
      user {
        name
        dateOfBirth
      }
    }
  }
`;
export const STARTING_IN = gql`
  subscription {
    startingIn {
      user {
        name
        startedAt
      }
    }
  }
`;
export const FINISHING_IN = gql`
  subscription {
    finishingIn {
      user {
        name
        leavingRequest {
          leavingAt
        }
      }
    }
  }
`;
export const PROBATIONARY_PERIOD_ENDING = gql`
  subscription {
    probationaryPeriodEnding {
      user {
        name
        startedAt
      }
    }
  }
`;

export const WORK_ANNIVERSARY = gql`
  subscription {
    workAnniversary {
      user {
        name
        startedAt
      }
    }
  }
`;

export const DOCUMENT_SHARED = gql`
  subscription {
    documentShared {
      document {
        attachmentName
        id
      }
    }
  }
`;

export const FETCH_DOCUMENT_ACTION_PENDING_USER_LIST = gql`
  query fetchDocument($id: ID!) {
    fetchDocument(id: $id) {
      id
      userDocumentConnections {
        userActionConnection {
          dateActioned
          id
        }
        user {
          id
          name
          profileImageUrl
        }
      }
    }
  }
`;

export default {
  LEAVE_SUBSCRIPTION_APPROVED,
  LEAVE_SUBSCRIPTION_DECLINED,
  LEAVE_SUBSCRIPTION_CREATED,
  BIRTHDAY_COMING_IN,
  FINISHING_IN,
  WORK_ANNIVERSARY,
  STARTING_IN,
  PROBATIONARY_PERIOD_ENDING,
  DOCUMENT_SHARED,
  FETCH_DOCUMENT_ACTION_PENDING_USER_LIST,
};
