import styled from "styled-components";
import { Col, Row, Space, Typography } from "antd";
import COLORS from "constants/colors";
import { BaseButton } from "components/Buttons";
import { useNotificationContext } from "contexts/NotificationProvider";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import leaveAlertBgImage from "../../assets/images/insights-leave-alert-bg.svg";
import NumberOfEmployees from "./numberOfEmployees/NumberOfEmployees";
import NotLoggedIn from "./notLoggedIn/NotLoggedIn";
import ComplianceIssues from "./complianceIssues/ComplianceIssues";
import EmployeesBreakdown from "./numberOfEmployeesBreakdown/EmployeesBreakdown";
import BurnoutRadar from "./burnoutRadar/BurnoutRadar";
import PeopleOnLeave from "./PeopleOnLeave/PeopleOnLeave";

const Insights = () => {
  const { employeePendingData, setOpenNotifications } = useNotificationContext();
  const { totalNumberOfEmployees } = PERMISSION;
  const { hasTotalNumberOfEmployees } = useCheckPermission({ totalNumberOfEmployees });
  return (
    <Space direction="vertical" size={[0, 12]} className="w-100">
      {employeePendingData && employeePendingData?.length > 0 && (
        <StyledDiv>
          <StyledLeaveAlert>
            <StyledLeaveAlertMassage level={3}>
              There are {employeePendingData.length} employee leave requests that require your action
            </StyledLeaveAlertMassage>
            <BaseButton onClick={() => setOpenNotifications(true)}>Action</BaseButton>
          </StyledLeaveAlert>
        </StyledDiv>
      )}
      <Row gutter={[12, 0]}>
        {hasTotalNumberOfEmployees && (
          <Col lg={17} xl={18} xxl={19}>
            <NumberOfEmployees />
          </Col>
        )}
        <Col lg={7} xl={6} xxl={5}>
          <NotLoggedIn />
        </Col>
      </Row>
      <Row gutter={[12, 0]}>
        <Col lg={11} xl={12} xxl={15}>
          <PeopleOnLeave />
        </Col>
        <Col lg={13} xl={12} xxl={9}>
          <BurnoutRadar />
        </Col>
      </Row>
      <Row gutter={[12, 0]}>
        <Col lg={9} xl={9} xxl={8}>
          <ComplianceIssues />
        </Col>
        <Col lg={15} xl={15} xxl={16}>
          <EmployeesBreakdown />
        </Col>
      </Row>
    </Space>
  );
};

export default Insights;

const StyledDiv = styled.div`
  background-image: url(${leaveAlertBgImage});
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 7px 36px;
  background-position: center;
  border-radius: 4px;
`;

const StyledLeaveAlert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const StyledLeaveAlertMassage = styled(Typography.Title)`
  &.ant-typography {
    font-size: 14px;
    color: ${COLORS.white};
    margin: 0px;
  }
`;
