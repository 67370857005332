import { Segmented, Typography } from "antd";
import { Container } from "components/core";
import {
  ComplianceByEmployee,
  ComplianceByIssue,
  ComplianceIssueEnum,
  ComplianceIssuesFilterEnum,
  complianceIssuesFilterLabel,
  ComplianceIssueTableData,
  UsersComplianceIssueAndBurnoutRadar,
  FetchComplianceIssueResponse,
  FetchComplianceIssueUserListArg,
} from "model/Insights";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { enumValues } from "utils/misc";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FETCH_USERS_COMPLIANCE_ISSUES_AND_BURNOUT_RADAR } from "services/graphql/insights";
import TABLE from "constants/table";
import { calculateHoursPerWeek, checkBreakCompliance, hoursPerWeekByWorkingTime, includesInArray } from "utils";
import { WorkingCondition, WorkingTimeEnum } from "model/Common";
import dayjs from "dayjs";
import TIME_FORMAT from "constants/timeFormat";
import { isManager } from "model/User";
import { useAuthContext } from "contexts";
import ComplianceIssuesTable from "./ComplianceIssuesTable";

const ComplianceIssues = () => {
  const [groupBy, setGroupBy] = useState<ComplianceIssuesFilterEnum>(ComplianceIssuesFilterEnum.Employee);
  const [data, setData] = useState<ComplianceIssueTableData>();
  const { user } = useAuthContext();

  const [refetch, { loading: refetchLoading }] = useLazyQuery<FetchComplianceIssueResponse>(
    FETCH_USERS_COMPLIANCE_ISSUES_AND_BURNOUT_RADAR,
  );

  const fetchUserData = useCallback(
    async ({ cursor, accumulated }: FetchComplianceIssueUserListArg) => {
      const result = await refetch({
        variables: {
          first: TABLE.rowsPerPage,
          after: cursor || undefined,
        },
      });
      if (result.data) {
        const { nodes, pageInfo } = result?.data?.fetchUsersComplianceIssueAndBurnoutRadar;
        const newData = [...(accumulated ?? []), ...nodes];
        if (pageInfo?.hasNextPage) {
          return fetchUserData({ accumulated: newData, cursor: pageInfo.endCursor });
        }
        return newData;
      }
      return [];
    },
    [refetch],
  );

  const checkBreakComplianceIssue = (workingCondition: WorkingCondition) => {
    if (!workingCondition?.workingDays?.length) {
      return undefined;
    }
    if (workingCondition?.workingTime === WorkingTimeEnum.Fixed) {
      const hoursPerDay = (hoursPerWeekByWorkingTime(workingCondition) ?? 0) / workingCondition.workingDays.length;
      const lunchBreak = workingCondition.workingDays[0]?.lunchBreak ?? 0;
      return !!checkBreakCompliance(hoursPerDay, lunchBreak);
    }

    const checkBreakForVariable = workingCondition.workingDays.some((workingDay) => {
      const lunchBreak = workingDay.lunchBreak ?? 0;
      const workingHoursPerDay = calculateHoursPerWeek({
        startTimeData: dayjs(workingDay.startTime, TIME_FORMAT.timeFormatWith24Hour, true),
        endTimeData: dayjs(workingDay.endTime, TIME_FORMAT.timeFormatWith24Hour, true),
        daysPerWeek: 1,
        lunchBreak,
      });
      return !!checkBreakCompliance(workingHoursPerDay, lunchBreak);
    });
    return checkBreakForVariable;
  };

  const modifyData = (userData: UsersComplianceIssueAndBurnoutRadar[]) => {
    const filterData = isManager(user)
      ? userData.filter((item) => includesInArray(user?.managedUserIds, item.id))
      : userData;

    const result = filterData.reduce(
      (acc, user) => {
        const issues = [];
        if (
          hoursPerWeekByWorkingTime(user?.workingCondition) > 1365 / 52 &&
          (user?.holidayBalance?.holidaysPerYear ?? 0) < 20
        )
          issues.push(ComplianceIssueEnum.Leave);
        if ((hoursPerWeekByWorkingTime(user?.workingCondition) ?? 0) > 48) issues.push(ComplianceIssueEnum.Hours);
        if (checkBreakComplianceIssue(user?.workingCondition)) issues.push(ComplianceIssueEnum.BREAK);

        if (issues.length) {
          acc.byEmployee.push({ id: user.id, name: user.name, profileImageUrl: user.profileImageUrl, issues });
          issues.forEach((issue) => {
            const issueObj = acc.byIssue.find((i: ComplianceByIssue) => i.issue === issue);
            if (issueObj) issueObj.users.push({ id: user.id, name: user.name, profileImageUrl: user.profileImageUrl });
            else
              acc.byIssue.push({
                issue,
                users: [{ id: user.id, name: user.name, profileImageUrl: user.profileImageUrl }],
              });
          });
        }
        return acc;
      },
      { byEmployee: [] as ComplianceByEmployee[], byIssue: [] as ComplianceByIssue[] },
    );
    setData(result);
  };

  const { loading } = useQuery<FetchComplianceIssueResponse>(FETCH_USERS_COMPLIANCE_ISSUES_AND_BURNOUT_RADAR, {
    variables: {
      first: TABLE.rowsPerPage,
    },
    onCompleted: (response) => {
      const { nodes, pageInfo } = response?.fetchUsersComplianceIssueAndBurnoutRadar;

      if (pageInfo?.hasNextPage) {
        fetchUserData({ accumulated: [...nodes], cursor: pageInfo.endCursor }).then((data) => modifyData(data));
      } else {
        modifyData([...nodes]);
      }
    },
  });

  return (
    <StyledContainer data-testid="complianceIssues">
      <StyledHeader>
        <Typography.Title level={4} className="m-0">
          Potential compliance issues
        </Typography.Title>
        <Segmented
          options={enumValues(ComplianceIssuesFilterEnum).map((l) => ({
            label: complianceIssuesFilterLabel[l],
            value: l,
          }))}
          value={groupBy}
          onChange={(newValue) => setGroupBy(newValue as ComplianceIssuesFilterEnum)}
        />
      </StyledHeader>

      <TabContentWrapper>
        <TabContent
          isActive={groupBy === ComplianceIssuesFilterEnum.Employee}
          animationType="right-to-left"
          className="tab-content"
        >
          <ComplianceIssuesTable
            filterBy={ComplianceIssuesFilterEnum.Employee}
            loading={loading || refetchLoading}
            data={data?.byEmployee ?? []}
          />
        </TabContent>
        <TabContent
          isActive={groupBy === ComplianceIssuesFilterEnum.Issue}
          animationType="left-to-right"
          className="tab-content"
        >
          <ComplianceIssuesTable
            filterBy={ComplianceIssuesFilterEnum.Issue}
            loading={loading || refetchLoading}
            data={data?.byIssue ?? []}
          />
        </TabContent>
      </TabContentWrapper>
    </StyledContainer>
  );
};

export default ComplianceIssues;

const StyledContainer = styled(Container)`
  padding: 0px;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 11px 19px 20px;
`;

const TabContentWrapper = styled.div`
  position: relative;
  height: 290px;
  overflow: hidden;
`;

const TabContent = styled.div<{ isActive: boolean; animationType: "left-to-right" | "right-to-left" }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  pointer-events: ${(props) => (props.isActive ? "auto" : "none")};

  animation: ${(props) =>
    props.isActive
      ? `fadeIn 0.5s ease-out, ${
          props.animationType === "left-to-right" ? "slideInLeftToRight" : "slideInRightToLeft"
        } 0.5s ease-out`
      : `fadeOut 0.5s ease-out, ${
          props.animationType === "left-to-right" ? "slideOutLeftToRight" : "slideOutRightToLeft"
        } 0.5s ease-out`};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slideInLeftToRight {
    0% {
      transform: translateX(-25px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeftToRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(25px);
    }
  }

  @keyframes slideInRightToLeft {
    0% {
      transform: translateX(25px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutRightToLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-25px);
    }
  }
`;
