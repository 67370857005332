import { useQuery } from "@apollo/client";
import { Empty, List, Modal, Space, Typography } from "antd";
import { LoadingPage } from "components/core";
import UserAvatar from "components/UserAvatar/UserAvatar";
import COLORS from "constants/colors";
import { FetchDocumentActionPendingUserList } from "model/Notification";
import { useMemo } from "react";
import { FETCH_DOCUMENT_ACTION_PENDING_USER_LIST } from "services/graphql/notification";
import styled from "styled-components";

type Props = {
  isModalVisible: boolean;
  hideModal: () => void;
  id?: string;
};

const DocumentActionPendingListModal = ({ isModalVisible, hideModal, id }: Props) => {
  const { data, loading } = useQuery<FetchDocumentActionPendingUserList>(FETCH_DOCUMENT_ACTION_PENDING_USER_LIST, {
    variables: { id },
    skip: !id,
  });

  const modifyData = useMemo(
    () =>
      data?.fetchDocument?.userDocumentConnections?.length
        ? data.fetchDocument.userDocumentConnections
            .filter((item) => !item.userActionConnection)
            .map((item) => item.user)
        : [],
    [data],
  );

  return (
    <StyledModal
      title={
        <StyledActionPendingModalTitle level={3}>
          Employees that have not actioned the Employee Handbook document
        </StyledActionPendingModalTitle>
      }
      open={isModalVisible}
      onCancel={hideModal}
      footer={null}
      width={428}
      centered
      destroyOnClose
    >
      {loading && <LoadingPage />}
      {!loading && modifyData.length > 0 && (
        <List
          size="small"
          bordered
          dataSource={modifyData}
          loading={loading}
          renderItem={(item) => (
            <List.Item>
              <Space size={[15, 0]}>
                <UserAvatar image={item?.profileImageUrl} section="header" />
                <StyledActionPendingUserName>{item?.name}</StyledActionPendingUserName>
              </Space>
            </List.Item>
          )}
        />
      )}

      {!loading && modifyData.length === 0 && (
        <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No users pending action on this document." />
      )}
    </StyledModal>
  );
};

export default DocumentActionPendingListModal;

const StyledActionPendingModalTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 14px;
    color: ${COLORS.inputFieldTextColor};
    max-width: 238px;
  }
`;

const StyledActionPendingUserName = styled(Typography.Text)`
  color: ${COLORS.inputFieldTextColor};
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 20px 30px 30px 30px;
  }

  .ant-list ul.ant-list-items {
    max-height: 475px;
    overflow: auto;
  }
`;

const StyledEmpty = styled(Empty)`
  border: 1px solid ${COLORS.borderLight};
  height: 161px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0;
`;
