export const TIME_FORMAT = {
  timeWithPeriodDefault: "h:mm A",
  timeWithPeriodPrimary: "h:mm a",
  timeWithPeriodSecondary: "h:mma",
  timeWithPeriodTertiary: "hh:mm A",
  timeFormatWith24Hour: "HH:mm",
  timeFormatWithoutPeriod: "h:mm",
  fullTimeFormat: "HH:mm:ss",
};
export default TIME_FORMAT;
