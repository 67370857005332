import { Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { PrimaryButton } from "components/Buttons";
import DATE_FORMAT from "constants/dateFormat";
import TABLE from "constants/table";
import dayjs from "dayjs";
import { mapLeaveRequestToFullDetail } from "features/employee/employeeDetail/leave/LeaveDetailTable";
import useFormModal from "hooks/useFormModal";
import {
  dayTypeLabels,
  EnhancedLeaveRequest,
  LeaveRequestsDayCompleteness,
  leaveTypeLabels,
  SelectedIdsData,
} from "model/Leave";
import { useState } from "react";
import styled from "styled-components";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import { useNotificationContext } from "contexts/NotificationProvider";
import { tableColumnCommonData, sortingTableData, SortTypeEnum, SortOrderEnum } from "components/Table";
import COLORS from "constants/colors";
import TIME_FORMAT from "constants/timeFormat";
import UpdateLeaveModal, { Data } from "./UpdateLeaveModal";

const ApproveLeaveTable = () => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<EnhancedLeaveRequest>>({});
  const { isModalVisible, hideModal, showModal } = useFormModal();
  const { employeePendingData: data, refetchEmployeePending: refetch } = useNotificationContext();
  const { approveOrDeclineLeave } = PERMISSION;
  const { hasApproveOrDeclineLeave } = useCheckPermission({ approveOrDeclineLeave });

  const columns: ColumnsType<EnhancedLeaveRequest> = [
    {
      ...tableColumnCommonData({
        title: "Employee",
        dataIndex: "name",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, r) => r.user!.name,
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.String, firstData: a.user!.name, secondData: b.user!.name }),
    },
    {
      ...tableColumnCommonData({
        title: "Leave type",
        dataIndex: "reason",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.String,
          firstData: leaveTypeLabels[a.reason],
          secondData: leaveTypeLabels[b.reason],
        }),
      render: (_, { reason }) => <span>{leaveTypeLabels[reason]}</span>,
    },
    {
      ...tableColumnCommonData({
        title: "Start day",
        dataIndex: "fromDate",
        defaultSortOrder: SortOrderEnum.Descend,
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { fromDate }) => <span>{fromDate ? dayjs(fromDate).format(DATE_FORMAT.fullDateDefault) : "-"}</span>,
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.fromDate, secondData: b.fromDate }),
    },
    {
      ...tableColumnCommonData({
        title: "End day",
        dataIndex: "toDate",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { toDate }) => <span>{toDate ? dayjs(toDate).format(DATE_FORMAT.fullDateDefault) : "-"}</span>,
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.toDate, secondData: b.toDate }),
    },
    {
      title: "Day type",
      render: (_, { dayCompleteness }) => (
        <span>{!dayCompleteness || dayCompleteness === LeaveRequestsDayCompleteness.Full ? "Full" : "Partial"}</span>
      ),
    },
    {
      title: "Day details",
      render: (_, { dayCompleteness, fromTime, toTime }) => (
        <span>
          {!dayCompleteness || dayCompleteness === LeaveRequestsDayCompleteness.Full
            ? "-"
            : dayCompleteness === LeaveRequestsDayCompleteness.Custom
            ? `${dayjs(fromTime, TIME_FORMAT.fullTimeFormat).format(TIME_FORMAT.timeWithPeriodTertiary)} - ${dayjs(
                toTime,
                TIME_FORMAT.fullTimeFormat,
              ).format(TIME_FORMAT.timeWithPeriodTertiary)}`
            : dayTypeLabels[dayCompleteness as LeaveRequestsDayCompleteness]}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "approve request",
      render: (_, r) =>
        hasApproveOrDeclineLeave && (
          <PrimaryButton
            onClick={() => {
              setSelectedRow(mapLeaveRequestToFullDetail(r));
              setIds({ requestId: r.id, userId: r.user?.id });
              showModal();
            }}
          >
            Approve/Decline
          </PrimaryButton>
        ),
      width: 145,
    },
  ];

  const [selectedRow, setSelectedRow] = useState<Data[]>();
  const [ids, setIds] = useState<SelectedIdsData>();

  return (
    <StyledWrapper>
      <Space direction="vertical" size={[0, 22]} className="w-100 section-box-shadow">
        <Typography.Title level={1} className="m-0 table-title">
          Leave requests
        </Typography.Title>
        <Table
          data-testid="leavePendingAlerts"
          columns={columns}
          dataSource={data ?? []}
          pagination={false}
          rowKey="id"
          sortDirections={TABLE.tableSortDirections}
          onChange={(_pagination, _filter, sorter) => setSortedInfo(sorter as SorterResult<EnhancedLeaveRequest>)}
        />
      </Space>
      {selectedRow && ids?.requestId && (
        <UpdateLeaveModal
          ids={ids}
          title="Leave request"
          data={selectedRow}
          okText="Approve request"
          cancelText="Decline request"
          open={isModalVisible}
          onClose={() => {
            hideModal();
            refetch();
          }}
        />
      )}
    </StyledWrapper>
  );
};

export default ApproveLeaveTable;

const StyledWrapper = styled.div`
  padding: 12px 20px 24px 27px;
  border-bottom: 1px solid ${COLORS.borderLight};

  .ant-table-column-sorter {
    display: none;
  }

  .ant-table-wrapper {
    border: 1px solid ${COLORS.borderLight};
  }

  .ant-table .ant-table-tbody tr td:first-child,
  .ant-table .ant-table-thead tr th:first-child,
  .ant-table .ant-table-thead tr td:first-child {
    padding-left: 17px;
  }
`;
