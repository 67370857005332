import EMPLOYEE from "constants/employee";
import { IntlType, WorkingConditionPayload } from "model/AdminSetting";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import dayjs, { Dayjs } from "dayjs";
import { expectNumber } from "utils";
import { WeekDayEnum, WorkingCondition, WorkingDays, WorkingTimeEnum } from "model/Common";
import TIME_FORMAT from "constants/timeFormat";
import isBetween from "dayjs/plugin/isBetween";
import DATE_FORMAT from "constants/dateFormat";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);

export const getUserTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return timeZone;
};

export const getSequenceOfDays = (data: string[]) => {
  const days = EMPLOYEE.days.map((item) => item.label);
  const daysIdArray = EMPLOYEE.days.filter((item) => data.includes(item.value as string)).map((item) => item.id);
  const ranges = daysIdArray
    .reduce((acc: (string | number)[][], val: number, i: number) => {
      if (i === 0 || val !== daysIdArray[i - 1] + 1) acc.push([]);
      acc[acc.length - 1].push(val);
      return acc;
    }, [])
    .map((range: (string | number)[]) =>
      range.length === 1
        ? days[expectNumber(range[0] as string) - 1]
        : `${days[expectNumber(range[0] as string) - 1]}-${days[expectNumber(range[range.length - 1] as string) - 1]}`,
    );
  return ranges.join(",");
};

export const getTimeZone = () => {
  const timeZoneIdentifiers: string[] = (Intl as typeof Intl & typeof IntlType).supportedValuesOf("timeZone");
  return [...timeZoneIdentifiers, ...(!timeZoneIdentifiers.includes("UTC") ? ["UTC"] : []), "GMT"];
};

export const isToday = (date: number, currentDate: Dayjs) => {
  const monthDate = dayjs(currentDate).startOf("month");
  const newDay = monthDate.clone().add(date, "day");
  const isToday = dayjs().isSame(newDay, "day") && dayjs().isSame(newDay, "month") && dayjs().isSame(newDay, "year");
  return isToday;
};

const getTotalDayTime = (start?: Dayjs, end?: Dayjs, lunchBreak?: number | null) => {
  if (start && end) {
    const startTime = dayjs(start).hour() * 60 + dayjs(start).minute();
    const endTime = dayjs(end).hour() * 60 + dayjs(end).minute();
    const duration = ((endTime - startTime) / 60).toFixed(2);
    const breakTime = (lunchBreak ? lunchBreak / 60 : 0).toFixed(2);
    return parseFloat(duration) - parseFloat(breakTime);
  }
  return 0;
};

export const calculateHoursPerWeek = ({
  workingCondition,
  daysPerWeek,
  startTimeData,
  endTimeData,
  lunchBreak,
}: {
  workingCondition?: Record<string, WorkingConditionPayload>;
  daysPerWeek?: number;
  startTimeData?: Dayjs;
  endTimeData?: Dayjs;
  lunchBreak?: number | null;
}): number => {
  if (workingCondition) {
    return Object.values(workingCondition).reduce((totalHours: number, day: WorkingConditionPayload) => {
      if (day.day) {
        return totalHours + getTotalDayTime(day.startTime, day.endTime, day.lunchBreak);
      }
      return totalHours;
    }, 0);
  }
  return daysPerWeek ? getTotalDayTime(startTimeData, endTimeData, lunchBreak) * daysPerWeek : 0;
};

export const modifyWorkingConditionPayload = ({
  workingTime,
  workingConditionPayload,
  daysPerWeek,
  startTimeData,
  endTimeData,
  lunchBreak,
}: {
  workingTime: WorkingTimeEnum;
  workingConditionPayload?: Record<string, WorkingConditionPayload>;
  daysPerWeek?: WeekDayEnum[];
  startTimeData?: string;
  endTimeData?: string;
  lunchBreak?: number | null;
}) => {
  let workingDays: WorkingDays[] = [];
  if (workingTime === WorkingTimeEnum.Fixed) {
    workingDays = (daysPerWeek || []).map((item) => ({
      endTime: dayjs(endTimeData).format(TIME_FORMAT.timeFormatWith24Hour),
      lunchBreak: lunchBreak ?? 0,
      startTime: dayjs(startTimeData).format(TIME_FORMAT.timeFormatWith24Hour),
      weekDay: item,
    }));
  } else {
    workingDays = workingConditionPayload
      ? Object.keys(workingConditionPayload).reduce((acc, day) => {
          if (workingConditionPayload[day].day) {
            return [
              ...acc,
              {
                endTime: dayjs(workingConditionPayload[day].endTime).format(TIME_FORMAT.timeFormatWith24Hour),
                lunchBreak: workingConditionPayload[day].lunchBreak ?? 0,
                startTime: dayjs(workingConditionPayload[day].startTime).format(TIME_FORMAT.timeFormatWith24Hour),
                weekDay: day as WeekDayEnum,
              },
            ];
          }
          return acc;
        }, [] as WorkingDays[])
      : [];
  }

  return workingDays;
};

export const formatWorkingDay = ({ startTime, endTime, lunchBreak }: WorkingDays) =>
  startTime
    ? {
        day: true,
        startTime: dayjs(startTime, TIME_FORMAT.timeFormatWith24Hour),
        endTime: dayjs(endTime, TIME_FORMAT.timeFormatWith24Hour),
        lunchBreak,
      }
    : { day: false };

export const getRelativeDate = (date: string | dayjs.Dayjs): string => {
  const inputDate = dayjs(date);
  const today = dayjs();
  const tomorrow = today.add(1, "day");
  const startOfWeek = today.startOf("week");
  const endOfWeek = today.endOf("week");
  const startOfNextWeek = startOfWeek.add(1, "week");
  const endOfNextWeek = endOfWeek.add(1, "week");

  if (inputDate.isSame(today, "day")) return "today";
  if (inputDate.isSame(tomorrow, "day")) return "tomorrow";
  if (inputDate.isBefore(today, "day")) return `on ${inputDate.format(DATE_FORMAT.fullDate)}`;
  if (inputDate.isBetween(startOfWeek, endOfWeek, null, "[]")) return `on ${inputDate.format("dddd")}`;
  if (inputDate.isBetween(startOfNextWeek, endOfNextWeek, null, "[]")) return "next week";

  return `on ${inputDate.format(DATE_FORMAT.fullDate)}`;
};

export const getTimeAgo = (inputDate?: string | null): string => {
  if (!inputDate) return "";

  const now = dayjs();
  const pastDate = dayjs(inputDate);
  let timeAgo = pastDate.from(now);
  timeAgo = timeAgo
    .replace("minutes", "min")
    .replace("hours", "h")
    .replace("minute", "min")
    .replace("hour", "h")
    .replace("a min", "1 min")
    .replace("an h", "1 h")
    .replace("a month", "1 month")
    .replace("a year", "1 year");
  return timeAgo;
};

export const hoursPerWeekByWorkingTime = (workingCondition?: WorkingCondition) => {
  if (workingCondition) {
    if (workingCondition.workingTime === WorkingTimeEnum.Fixed) {
      return calculateHoursPerWeek({
        daysPerWeek: workingCondition?.workingDays.length,
        startTimeData: dayjs(workingCondition?.workingDays[0].startTime, TIME_FORMAT.timeFormatWith24Hour),
        endTimeData: dayjs(workingCondition?.workingDays[0].endTime, TIME_FORMAT.timeFormatWith24Hour),
        lunchBreak: workingCondition?.workingDays[0].lunchBreak,
      });
    }
    const modifyWorkingCondition = workingCondition.workingDays.reduce(
      (acc, day) => ({
        ...acc,
        [day.weekDay]: formatWorkingDay(day),
      }),
      {} as Record<string, WorkingConditionPayload>,
    );
    return calculateHoursPerWeek({ workingCondition: modifyWorkingCondition });
  }
  return 0;
};

export default {
  getUserTimeZone,
  getSequenceOfDays,
  getTimeZone,
  isToday,
  calculateHoursPerWeek,
  modifyWorkingConditionPayload,
  formatWorkingDay,
  getRelativeDate,
  getTimeAgo,
  hoursPerWeekByWorkingTime,
};
