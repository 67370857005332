import { Table, Typography } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { ColumnsType } from "antd/lib/table";
import { DefaultButton } from "components/Buttons";
import Icon from "components/Icon";
import { tableColumnCommonData, sortingTableData, SortTypeEnum, SortOrderEnum } from "components/Table";
import DATE_FORMAT from "constants/dateFormat";
import dayjs from "dayjs";
import { Documents, documentSharedWith } from "model/Document";
import { useState } from "react";
import { useParams } from "react-router-dom";
import TABLE from "constants/table";
import { useAuthContext } from "contexts";
import { downloadLink } from "utils";
import styled from "styled-components";
import { getFilenameFromUrl, removeExtensionFromName } from "../../leave";

type Props = {
  tableFor: "companyDocuments" | "employeeDocuments" | "allDocuments";
  data: Documents[];
  loading: boolean;
};

const DocumentTable = ({ tableFor, data, loading }: Props) => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const userId = id || user?.id;
  const isMyProfile = userId === user?.id;
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Documents>>({});

  const columns: ColumnsType<Documents> = [
    {
      ...tableColumnCommonData({
        title: "Document name",
        dataIndex: "attachmentName",
        defaultSortOrder: SortOrderEnum.Descend,
        width: 500,
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.String, firstData: a!.attachmentName, secondData: b!.attachmentName }),
      render: (_, { attachmentName }) => (
        <StyledDocumentName level={3} className="m-0 d-flex gap-2 py-2">
          <Icon name="document" />
          {removeExtensionFromName(attachmentName)}
        </StyledDocumentName>
      ),
    },
    ...(isMyProfile
      ? ([
          {
            ...tableColumnCommonData({
              title: "Shared with",
              dataIndex: "visibilityType",
              sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
            }),
            render: (_, { visibilityType }) => documentSharedWith[visibilityType],
            sorter: (a, b) =>
              sortingTableData({
                type: SortTypeEnum.String,
                firstData: documentSharedWith[a.visibilityType],
                secondData: documentSharedWith[b.visibilityType],
              }),
          },
        ] as ColumnsType<Documents>)
      : []),
    {
      ...tableColumnCommonData({
        title: "Date added",
        dataIndex: "dateAdded",
        width: !isMyProfile ? "45%" : undefined,
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { dateAdded }) => (dateAdded ? dayjs(dateAdded).format(DATE_FORMAT.fullDateDefault) : "N/A"),
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.dateAdded, secondData: b.dateAdded }),
    },
    {
      title: "",
      dataIndex: "Download",
      width: 100,
      align: "right",
      render: (_, { attachmentUrl }) => (
        <DefaultButton
          onClick={(event) => {
            event.stopPropagation();
            downloadLink(attachmentUrl, getFilenameFromUrl(attachmentUrl));
          }}
        >
          Download
        </DefaultButton>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        rowKey="id"
        scroll={{
          scrollToFirstRowOnChange: false,
          y: tableFor === "allDocuments" ? undefined : TABLE.tableDefaultHeight,
        }}
        sortDirections={TABLE.tableSortDirections}
        onChange={(_pagination, _filter, sorter) => setSortedInfo(sorter as SorterResult<Documents>)}
        sticky={isMyProfile && tableFor === "allDocuments"}
      />
    </>
  );
};

export default DocumentTable;

const StyledDocumentName = styled(Typography.Title)`
  font-size: 14px;
`;
