import { Col, Row, Form, Typography, InputNumber } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Container } from "components/core";
import COLORS from "constants/colors";
import useFormModal from "hooks/useFormModal";
import {
  BillingRef,
  BillingFormArg,
  UpsertBillingCustomerResponse,
  CreateSetupIntentResponse,
  SubscriptionResponse,
  BillingDetailsRef,
  FetchPromotionCodeResponse,
  PricesIntervalEnum,
  PromotionCodesPaymentTypeEnum,
} from "model/AdminSetting";
import styled from "styled-components";
import { useAuthContext } from "contexts";
import { forwardRef, useImperativeHandle, useRef } from "react";
import UnsavedPrompt from "components/UnsavedPrompt";
import {
  BILLING_UPSERT_CUSTOMER,
  CREATE_BILLING_SETUP_INTENT,
  FETCH_BILLING_PROMOTION_CODE,
  UPSERT_BILLING_SUBSCRIPTION,
} from "services/graphql/adminSetting";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useNotify } from "services/api";
import { FeedBackModal } from "components/modal";
import { DefaultButton } from "components/Buttons";
import { InputText } from "components/Inputs";
import ALERTS from "config/alerts";
import { useRollbar } from "@rollbar/react";
import BillingPackageSection from "./BillingPackageSection";
import BillingDetailsSection from "./BillingDetailsSection";
import useBilling, { Actions } from "./useBilling";

interface Props {
  fetchDataAfterMutation: () => void;
}

export interface IsMutationCall {
  package?: boolean;
  address?: boolean;
  card?: boolean;
}

const BillingForm = forwardRef<BillingRef, Props>(({ fetchDataAfterMutation }, ref) => {
  const { user } = useAuthContext();
  const [form] = useForm();

  const { billingState, dispatch } = useBilling();

  const billingDetailsRef = useRef<BillingDetailsRef>(null);

  const {
    isModalVisible: isSuccessModalVisible,
    hideModal: hideSuccessModal,
    showModal: showSuccessModal,
  } = useFormModal();

  const stripe = useStripe();
  const elements = useElements();
  const notify = useNotify();
  const rollbar = useRollbar();

  const [upsertSubscription] = useMutation<SubscriptionResponse>(UPSERT_BILLING_SUBSCRIPTION);

  const [mutate] = useMutation<UpsertBillingCustomerResponse>(BILLING_UPSERT_CUSTOMER);

  const [createSetupIntent] = useMutation<CreateSetupIntentResponse>(CREATE_BILLING_SETUP_INTENT);

  const [fetchPromotionCode, { loading }] = useLazyQuery<FetchPromotionCodeResponse>(FETCH_BILLING_PROMOTION_CODE, {
    onCompleted: (response) => {
      if (response?.fetchPromotionCode) {
        dispatch({ type: Actions.PromoCode, payload: response?.fetchPromotionCode });
      }
    },
  });

  const promoCode = () => {
    const interval = form.getFieldValue("interval");
    if (interval && billingState?.promotionCode && billingState?.priceData) {
      const priceData = billingState.priceData.filter((price) => price.id === interval)[0];
      fetchPromotionCode({
        variables: {
          paymentType:
            priceData?.interval === PricesIntervalEnum.Year
              ? PromotionCodesPaymentTypeEnum.Annually
              : PromotionCodesPaymentTypeEnum.Monthly,
          promotionCode: billingState.promotionCode,
        },
      });
    }
  };

  const mutationCallHandler = (value: IsMutationCall) => {
    dispatch({ type: Actions.IsMutationCall, payload: { ...billingState.isMutationCall, ...value } });
    isSubmitButtonDisabled({ ...billingState.isMutationCall, ...value });
  };

  const confirmCardSetup = async (cardHolderName: string, clientSecret: string) => {
    if (!stripe || !elements) {
      notify.error(undefined, "Stripe.js hasn't yet loaded");
      return undefined;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      notify.error(undefined, "Missing card details");
      return undefined;
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          name: cardHolderName,
        },
      },
    });
    return result;
  };

  const handelSubmit = async (value: BillingFormArg) => {
    if (value) {
      dispatch({ type: Actions.IsProcessing, payload: true });

      if (billingState.isMutationCall?.address && value?.businessDetail.complete && value.email) {
        const { address, name, phone } = value.businessDetail.value;
        const { postal_code: postalCode, ...addressData } = address;
        const upsertCustomerData = await mutate({
          variables: {
            name,
            phone,
            postalCode,
            ...addressData,
            email: value.email,
          },
        });
        if (
          upsertCustomerData?.errors?.length ||
          (upsertCustomerData?.data?.upsertCustomer &&
            upsertCustomerData?.data?.upsertCustomer.errors?.fullMessages.length)
        ) {
          dispatch({ type: Actions.IsProcessing, payload: false });
          upsertCustomerData?.data?.upsertCustomer.errors?.fullMessages?.map((error: string) =>
            notify.error(undefined, error),
          );
          notify.error(undefined, "Something went wrong. Please try again");
          return;
        }
      }
      let paymentMethodId: string | undefined;
      if (
        billingState.isMutationCall?.card &&
        value.cardHolderName &&
        !value.cardNumber.empty &&
        !value.cvv.empty &&
        !value.expirationDate.empty
      ) {
        const createSetupIntentResponse = await createSetupIntent();
        if (createSetupIntentResponse?.data && createSetupIntentResponse?.data.createSetupIntent.clientSecret) {
          if (value && value?.cardHolderName) {
            const confirmCardSetupResponse = await confirmCardSetup(
              value.cardHolderName,
              createSetupIntentResponse.data.createSetupIntent.clientSecret,
            );

            if (confirmCardSetupResponse && confirmCardSetupResponse.error) {
              rollbar.info(confirmCardSetupResponse.error.message, confirmCardSetupResponse.error, {
                id: user?.id,
                name: user?.name,
                email: user?.email,
                role: user?.roles,
              });
              notify.error(undefined, confirmCardSetupResponse.error.message);
              dispatch({ type: Actions.IsProcessing, payload: false });
              notify.error(undefined, "Something went wrong. Please try again");
              return;
            }
            if (!billingState?.defaultCardDetails && confirmCardSetupResponse?.setupIntent?.payment_method) {
              paymentMethodId = confirmCardSetupResponse.setupIntent.payment_method as string;
            }
          }
        }
      }

      if (billingState.isMutationCall?.package && value.interval && value.quantity) {
        const upsertSubscriptionData = await upsertSubscription({
          variables: {
            priceId: value.interval,
            promotionCode: billingState?.promoCode?.code,
            quantity: value.quantity,
            ...(paymentMethodId ? { stripePaymentMethodId: paymentMethodId } : {}),
          },
        });
        if (
          !upsertSubscriptionData ||
          upsertSubscriptionData?.errors?.length ||
          (upsertSubscriptionData?.data?.upsertSubscription &&
            upsertSubscriptionData?.data?.upsertSubscription.errors?.fullMessages.length)
        ) {
          dispatch({ type: Actions.IsProcessing, payload: false });
          upsertSubscriptionData?.data?.upsertSubscription.errors?.fullMessages?.map((error: string) =>
            notify.error(undefined, error),
          );
          notify.error(undefined, "Something went wrong. Please try again");
          return;
        }
      }

      dispatch({ type: Actions.IsProcessing, payload: false });
      showSuccessModal();
      elements?.getElement(CardNumberElement)?.clear();
      elements?.getElement(CardExpiryElement)?.clear();
      elements?.getElement(CardCvcElement)?.clear();
      form.resetFields(["cardHolderName"]);
      dispatch({ type: Actions.IsSaveDisabled, payload: true });
      dispatch({ type: Actions.IsMutationCall, payload: {} });
    }
  };

  const isSubmitButtonDisabled = (isMutationCall: IsMutationCall) => {
    const data = form.getFieldsValue();
    const isFieldsChanged = Object.values(isMutationCall).some((value) => value === true);
    const isDisable = Object.keys(data)
      .map((field) => {
        if (field === "businessDetail") {
          return !!data[field] && data[field].complete;
        }
        if (field === "cardNumber" || field === "expirationDate" || field === "cvv" || field === "cardHolderName") {
          if (billingState?.defaultCardDetails) {
            const isData = field === "cardHolderName" ? data[field] : data[field] && !data[field].empty;
            if (isData) {
              const cardField = ["cardNumber", "expirationDate", "cvv"]
                .map((item) => !!data[item] && data[item].complete && !data[item].error)
                .every((data) => data === true);
              const cardHolder = !!data.cardHolderName && !form.getFieldError("cardHolderName").length;

              return cardField && cardHolder;
            }
            return true;
          }
          return field === "cardHolderName"
            ? !!data[field] && !form.getFieldError(field).length
            : !!data[field] && data[field].complete && !data[field].error;
        }
        if (data[field] && !form.getFieldError(field).length) {
          return true;
        }
        return !!data[field] && !form.getFieldError(field).length;
      })
      .some((value) => value === false);

    dispatch({ type: Actions.IsSaveDisabled, payload: !isFieldsChanged ? true : isDisable });
  };

  useImperativeHandle(ref, () => ({
    resetForm() {
      form.resetFields();
    },
    setIsFieldsChanged() {
      dispatch({ type: Actions.IsMutationCall, payload: {} });
    },
    isFieldsChanged: Object.values(billingState.isMutationCall).some((value) => value === true),
    refetchCustomerDetails() {
      if (billingDetailsRef?.current) {
        billingDetailsRef.current.refetch();
      }
    },
    setIsSaveDisabled() {
      dispatch({ type: Actions.IsSaveDisabled, payload: true });
    },
  }));

  return (
    <StyledContainer className="section-box-shadow">
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          email: user?.email,
        }}
        onFinish={(value: BillingFormArg) => {
          const isFieldsChanged = Object.values(billingState.isMutationCall).some((value) => value === true);
          if (isFieldsChanged && value) {
            handelSubmit(value);
          }
        }}
      >
        <StyledEmployeeSection className="justify-content-between mb-4">
          <Col md={9} lg={9} xl={10} className="noOfEmployee">
            <Row className="mb-2">
              <Col span={24}>
                <StyledSubTitle level={5}>Number of employees</StyledSubTitle>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <Form.Item name="quantity" required={false} rules={[ALERTS.required]}>
                  <InputNumber
                    className="w-100"
                    min={1}
                    data-testid="quantity"
                    controls={false}
                    disabled={billingState?.currentPackage?.quantity ? !!billingState.currentPackage.quantity : false}
                    onChange={() => mutationCallHandler({ package: true })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <StyledValidationSpan>
              You will initially be charged based on the number of employees entered. If this number changes in the
              future, your bill will be updated accordingly
            </StyledValidationSpan>
          </Col>
          {!billingState.defaultCardDetails && (
            <Col md={9} lg={9} xl={10}>
              <Row className="mb-2">
                <Col span={24}>
                  <StyledSubTitle level={5}>Promo code</StyledSubTitle>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col span={14} className="d-flex gap-2">
                  <Form.Item className="w-100 mb-0">
                    <InputText
                      value={billingState.promotionCode}
                      onChange={(e) => dispatch({ type: Actions.PromotionCode, payload: e?.currentTarget?.value })}
                    />
                  </Form.Item>
                  <DefaultButton onClick={promoCode} loading={loading}>
                    Apply
                  </DefaultButton>
                </Col>
              </Row>
            </Col>
          )}
        </StyledEmployeeSection>
        <Row>
          <Col span={24}>
            <BillingPackageSection
              form={form}
              billingState={billingState}
              dispatch={dispatch}
              setIsMutationCall={mutationCallHandler}
              refetchPromoCode={promoCode}
            />
          </Col>
        </Row>
        <BillingDetailsSection
          form={form}
          setIsMutationCall={mutationCallHandler}
          billingState={billingState}
          dispatch={dispatch}
          ref={billingDetailsRef}
        />
      </Form>
      <FeedBackModal
        open={isSuccessModalVisible}
        type="success"
        onCancel={() => {
          hideSuccessModal();
          fetchDataAfterMutation();
        }}
        onOk={() => {
          hideSuccessModal();
          fetchDataAfterMutation();
        }}
        title="Your changes have been saved"
      />
      <UnsavedPrompt when={Object.values(billingState.isMutationCall).some((value) => value === true)} />
    </StyledContainer>
  );
});

export default BillingForm;

const StyledContainer = styled(Container)`
  padding: 13px 13px 31px 13px;

  .border-bottom-light {
    border-bottom: 1px solid ${COLORS.borderLight};
  }
  @keyframes name-of-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  div.packages {
    display: flex;
    width: 100%;
    animation: name-of-animation 0.5s;
  }
  div.addressElement {
    animation: name-of-animation 0.5s;
  }
`;

const StyledSubTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 14px;
    margin: 0;
    margin-bottom: 0px;
    line-height: 22px;
  }
`;

const StyledValidationSpan = styled.span`
  font-weight: 400;
  font-style: italic;
  color: ${COLORS.disabledColor};
  width: 510px;
  display: block;
`;

const StyledEmployeeSection = styled(Row)`
  margin-top: 19px;
  padding-left: 27px;
`;
