import { Col, Modal, Row, TabsProps } from "antd";
import { Tabs } from "components/Tabs";
import { PrimaryButton } from "components/Buttons";
import styled from "styled-components";
import useFormModal from "hooks/useFormModal";
import LeaveForm from "features/users/LeaveForm";
import useCreateLeaveRequest from "hooks/useCreateLeaveRequest";
import { useAuthContext } from "contexts";
import { LeaveRequestArg } from "model/Leave";
import { useFetchRequestList } from "contexts/LeaveRequestListProvider";
import LeaveSummary from "features/leave/LeaveSummary";
import LeaveDetailTable from "features/employee/employeeDetail/leave/LeaveDetailTable";
import { Container } from "components/core";
import DocumentTable from "features/employee/employeeDetail/documents/DocumentTable";
import Insights from "features/insights/Insights";
import { HomeTabKeyEnum, isAdmin } from "model/User";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Documents, ProfileDocumentResponse } from "model/Document";
import { FETCH_PROFILE_DOCUMENT } from "services/graphql/document";

const Home = () => {
  const { user } = useAuthContext();
  const { refetchList } = useFetchRequestList();
  const { showModal, hideModal, isModalVisible, onCloseModal, onFieldsChange } = useFormModal();
  const [tabActiveKey, setTabActiveKey] = useState<string>(
    isAdmin(user) ? HomeTabKeyEnum.Insights : HomeTabKeyEnum.Leaves,
  );
  const [documentData, setDocumentData] = useState<Documents[]>([]);
  const { mutate, loading } = useCreateLeaveRequest(
    user?.id,
    () => {
      hideModal();
      refetchList(user?.id);
    },
    "request",
  );

  const { loading: documentLoading } = useQuery<ProfileDocumentResponse>(FETCH_PROFILE_DOCUMENT, {
    variables: { notActioned: false },
    onCompleted: (response) => {
      const documents: Documents[] = response.profileDocuments.documents.map((d: Documents) => ({ ...d })) ?? [];
      setDocumentData((d) => [...d, ...documents]);
    },
    fetchPolicy: "no-cache",
    skip: tabActiveKey !== HomeTabKeyEnum.Documents,
  });

  const items: TabsProps["items"] = [
    {
      key: HomeTabKeyEnum.Insights,
      label: "Insights",
      children: <Insights />,
      show: isAdmin(user),
    },
    {
      key: HomeTabKeyEnum.Leaves,
      label: `My leave `,
      children: (
        <div>
          <Row className="mb-4">
            <Col span={24}>
              <LeaveSummary />
            </Col>
          </Row>
          <LeaveDetailTable />
        </div>
      ),
      show: true,
    },
    {
      key: HomeTabKeyEnum.Documents,
      label: `My documents`,
      children: (
        <StyledDocumentTabWrapper>
          <Container className="p-0 section-box-shadow">
            <DocumentTable tableFor="allDocuments" data={documentData} loading={documentLoading} />
          </Container>
        </StyledDocumentTabWrapper>
      ),
      show: true,
    },
  ].filter((item) => item.show);

  return (
    <div id="main" className="h-100">
      <StyledWrapper>
        {(tabActiveKey === HomeTabKeyEnum.Leaves || tabActiveKey === HomeTabKeyEnum.Documents) && (
          <StyledPrimaryButton onClick={showModal}>Request leave</StyledPrimaryButton>
        )}
        <StyledTab
          defaultActiveKey={tabActiveKey}
          items={items}
          id="home_tabs"
          destroyInactiveTabPane
          onTabClick={(activeKey) => {
            setTabActiveKey(activeKey);
          }}
        />
        <Modal
          width={385}
          title="Request Leave"
          open={isModalVisible}
          footer={null}
          onCancel={onCloseModal}
          destroyOnClose
          centered
        >
          <LeaveForm
            leaveFormType="request"
            onFieldsChange={onFieldsChange}
            loading={loading}
            onSubmit={(values: LeaveRequestArg) => mutate({ variables: { ...values, userId: user?.id } })}
          />
        </Modal>
      </StyledWrapper>
    </div>
  );
};

export default Home;

const StyledWrapper = styled.div`
  position: relative;
  margin-top: 5px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  position: absolute;
  right: 0;
  top: -18px;
  z-index: 1;
`;

const StyledTab = styled(Tabs)`
  .ant-tabs-nav-list {
    margin-left: 4px;
  }
`;

const StyledDocumentTabWrapper = styled.div`
  .ant-table-column-sorter {
    display: none;
  }
`;
